import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import TrainingForm from '../../pages/WorkoutsPage/TrainingForm/TrainingForm';
import { Training } from '../../types/Training';
import { DialogActionsStyled } from '../modal.styles';
import { Color } from '../../styles/colors';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const TrainingCreateFormModal = ({
  onCancel,
  onChange,
  params,
}: {
  onCancel: () => void;
  onChange: (data: any) => void;
  params?: any;
}) => {
  const [submitFormTrigger, setSubmitFormTrigger] = useState<number>(0);
  const [notifyClient, setNotifyClient] = useState<boolean>(false);
  const [isClientInTraining, setIsClientInTraining] = useState<boolean>(false);
  const [isRepeatingEventEnabled, setIsRepeatingEventEnabled] =
    useState<boolean>(false);
  const [showMoreBar, setShowMoreBar] = useState(false);
  const [forceSave, setForceSave] = useState(false);

  const handleOnSubmit = () => {
    setIsRepeatingEventEnabled(false);
    setSubmitFormTrigger(submitFormTrigger + 1);
  };

  const handleOnSubmitRepeating = (force?: boolean) => {
    setIsRepeatingEventEnabled(true);
    if (force) {
      setForceSave(true);
    }
    setSubmitFormTrigger(submitFormTrigger + 1);
  };

  const onTrainingDataChanged = (data: Training) => {
    onChange(data);
  };

  const onFormError = () => {
    setIsRepeatingEventEnabled(false);
    setForceSave(false);
  };

  const handleCheckIsClient = (isClient: boolean) => {
    setIsClientInTraining(isClient);
    setNotifyClient(isClient);
  };

  useEffect(() => {
    const content = window.document.getElementById('EventFormModalContent');
    const createForm = window.document.getElementById('EventCreateForm');
    if (content && createForm) {
      new ResizeObserver(() => {
        handleShowMoreBar(
          content.offsetHeight + content.scrollTop,
          createForm.offsetHeight
        );
      }).observe(content);
      content.addEventListener('wheel', () => {
        handleShowMoreBar(
          content.offsetHeight + content.scrollTop,
          createForm.offsetHeight
        );
      });
      createForm.addEventListener('scroll', () => {
        handleShowMoreBar(
          content.offsetHeight + content.scrollTop,
          createForm.offsetHeight
        );
      });
      createForm.addEventListener('touchend', () => {
        handleShowMoreBar(
          content.offsetHeight + content.scrollTop,
          createForm.offsetHeight
        );
      });
    }
  }, []);

  const handleShowMoreBar = (
    containerHeight: number,
    contentHeight: number
  ) => {
    setShowMoreBar(containerHeight + 20 < contentHeight);
  };

  return (
    <>
      <DialogTitle>
        {params.trainingId
          ? 'Edycja treningu'
          : `Zaplanuj trening${isRepeatingEventEnabled ? 'i' : ''}`}
      </DialogTitle>
      <DialogContent id="EventFormModalContent">
        <TrainingForm
          trainingId={params.trainingId}
          notifyClient={notifyClient}
          onCheckIsClient={handleCheckIsClient}
          initialStartDate={params.startDate}
          initialStartTime={params.startTime}
          initialDurationTime={params.durationTime}
          submitFormTrigger={submitFormTrigger}
          onFormSubmit={onTrainingDataChanged}
          onFormError={onFormError}
          isRepeatingEventEnabled={isRepeatingEventEnabled}
          forceSave={forceSave}
        />
      </DialogContent>

      <DialogContent
        sx={{
          overflow: 'hidden',
          padding: 0,
          height: '70px',
        }}
      >
        {showMoreBar && (
          <Box
            sx={{
              position: 'relative',
              display: 'block',
              width: '100%',
              height: '30px',
              paddingTop: '2px',
              paddingBottom: '5px',
              backgroundColor: Color.lightGrey,
              textAlign: 'center',
              svg: { color: Color.black },
            }}
          >
            <KeyboardDoubleArrowDownIcon />
            <KeyboardDoubleArrowDownIcon />
            <KeyboardDoubleArrowDownIcon />
          </Box>
        )}
      </DialogContent>

      {!params.trainingId && isRepeatingEventEnabled && (
        <DialogActionsStyled>
          <Button
            onClick={() => setIsRepeatingEventEnabled(false)}
            variant={'outlined'}
            color={'primary'}
          >
            Wstecz
          </Button>
          <Button
            onClick={() => onCancel()}
            variant={'outlined'}
            color={'primary'}
          >
            Anuluj
          </Button>
          <Button
            onClick={() => handleOnSubmitRepeating(true)}
            variant={'contained'}
            color={'primary'}
          >
            Dodaj
          </Button>
        </DialogActionsStyled>
      )}

      {!isRepeatingEventEnabled && (
        <DialogActionsStyled>
          {params.trainingId && isClientInTraining && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={notifyClient}
                  onChange={(ev) => setNotifyClient(!notifyClient)}
                />
              }
              label={'Wyślij powiadomienie e-mail do klienta'}
            />
          )}

          <Button onClick={() => onCancel()} variant={'outlined'}>
            Anuluj
          </Button>
          {!params.trainingId && (
            <Button
              onClick={() => handleOnSubmitRepeating(false)}
              variant={'outlined'}
              color={'primary'}
            >
              Dodaj serię
            </Button>
          )}
          <Button
            onClick={handleOnSubmit}
            variant={'contained'}
            color={'primary'}
          >
            Zapisz
          </Button>
        </DialogActionsStyled>
      )}
    </>
  );
};
export default TrainingCreateFormModal;
