import styled from '@emotion/styled';
import { Color } from './colors';

export const Optional = styled('span')({
  color: Color.grey,
  fontSize: 12,
  backgroundColor: Color.lightGrey,
  padding: '2px 5px',
  borderRadius: 4,
  marginLeft: '5px',
});
