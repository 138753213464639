import React, { useState } from 'react';
import { FieldType } from '../../types/FieldType';
import { Alert, FormLabel } from '@mui/material';
import { Optional } from '../../styles/general';
import InfoIcon from '@mui/icons-material/Info';

const FormerLabel = ({ fieldItem }: { fieldItem: FieldType }) => {
  const [showDescription, setShowDescription] = useState<boolean>(
    fieldItem.labelDescriptionOpened || false
  );

  return (
    <div className="FormLabelContainer">
      <FormLabel className="FormLabel">
        {fieldItem.label}{' '}
        {!fieldItem.required && <Optional>opcjonalne</Optional>}{' '}
        {fieldItem.labelDescription && (
          <InfoIcon onClick={() => setShowDescription(!showDescription)} />
        )}
      </FormLabel>
      {showDescription && fieldItem.labelDescription && (
        <Alert severity={'info'}>{fieldItem.labelDescription}</Alert>
      )}
    </div>
  );
};
export default FormerLabel;
