import React, { useEffect, useState } from 'react';
import {
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
} from '@mui/material';
import http from '../../api/http';
import { ClientPackage } from '../../types/ClientPackage';
import { Package } from '../../types/Package';
import { useInfo } from '../../providers/info/info.context';
import moment from 'moment';
import styled from '@emotion/styled';
import { Color } from '../../styles/colors';

const ClientPackages = ({ clientId }: { clientId: number }) => {
  const [clientPackages, setClientPackages] = useState<ClientPackage[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState<number>(0);
  const { setLoading, showMessage } = useInfo();

  useEffect(() => {
    getClientPackages();
    getPackagesList();
  }, [clientId]);

  const getPackagesList = () => {
    setLoading(true);
    http()
      .get('/packages', { params: { clientId } })
      .then(({ data }) => {
        setPackages(data);
        setSelectedPackageId(data[0].id);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getClientPackages = () => {
    setLoading(true);
    http()
      .get(`/clients/${clientId}/packages`)
      .then(({ data }) => {
        setClientPackages(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onPackageChange = (ev: SelectChangeEvent<number>) => {
    setSelectedPackageId(ev.target.value as number);
  };

  const onClientPackageCreate = (packageId: number) => {
    setLoading(true);
    http()
      .post(`/clients/${clientId}/packages/${packageId}`)
      .then(() => {
        getPackagesList();
        getClientPackages();
        showMessage('Pakiet został przypisany', 'success');
      })
      .catch((error) => {
        showMessage(
          error.response?.data?.message ||
            'Wystąpił błąd podczas przypisania pakietu, spróbuj ponownie',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="ClientPackages">
      <strong>Pakiety przypisane do klienta</strong>

      <>
        {clientPackages.length > 0 ? (
          <ClientPackagesList>
            {clientPackages.map((pckg) => {
              return (
                <ClientPackageRow key={pckg.id}>
                  <strong>{pckg.name}</strong>
                  <br />
                  Wykorzystane treningi:{' '}
                  <strong>
                    {pckg.trainingsCountUsed} / {pckg.trainingsCount}
                  </strong>
                  <br />
                  Data ważności:{' '}
                  <strong>
                    {moment.unix(pckg.endTimestamp).format('DD.MM.YYYY')}
                  </strong>
                </ClientPackageRow>
              );
            })}
          </ClientPackagesList>
        ) : (
          <p>Brak przypisanych pakietów.</p>
        )}
      </>

      {packages.length > 0 && (
        <AvailablePackagesToAssign>
          <strong>Przypisz pakiet:</strong>
          <br />
          <Select
            label={''}
            onChange={onPackageChange}
            variant={'outlined'}
            size={'small'}
            placeholder={'Wybierz pakiet'}
            value={selectedPackageId}
          >
            {Array.isArray(packages) &&
              packages.map((item, k) => {
                return (
                  <MenuItem key={k} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
          <br />
          <Button
            onClick={() => onClientPackageCreate(selectedPackageId)}
            variant={'contained'}
          >
            Przypisz pakiet treningów
          </Button>
        </AvailablePackagesToAssign>
      )}
    </div>
  );
};
export default ClientPackages;

const ClientPackagesList = styled(Box)({
  marginBottom: 20,
  paddingTop: 10,
});

const ClientPackageRow = styled(Box)({
  padding: '10px',
  border: `solid 1px ${Color.grey}`,
  borderRadius: '4px',
  marginBottom: '20px',
});

const AvailablePackagesToAssign = styled('div')({
  '> div': {
    marginBottom: '20px',
  },
});
