import React from 'react';
import styled from '@emotion/styled';
import { Box, FormControl } from '@mui/material';

export const SwitchFormControl = styled(FormControl)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const PageContent = styled(Box)({
  padding: 20,
});
