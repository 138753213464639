import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { marketplaceUrl } from '../../config/config';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Color } from '../../styles/colors';
import { PageContent } from './ProfilePage.styles';
import { Link } from '@mui/material';

const ProfilePreview = () => {
  const navigate = useNavigate();
  const [trainerData] = useOutletContext<any>();
  const [previewUrl, setPreviewUrl] = useState<string>('');

  useEffect(() => {
    if (trainerData.isActive && trainerData.slug) {
      setPreviewUrl(`${marketplaceUrl}/trener/${trainerData.slug}`);
    }
  }, [trainerData]);

  return (
    <>
      <PageHeader
        label={'Podgląd wizytówki'}
        onBackButtonClick={() => navigate('/profile')}
      />
      {!previewUrl && (
        <PageContent>
          <p>
            Podgląd wizytówki jest niedostępny, ponieważ jest ona nieaktywna.
          </p>
          <p>
            Uzupełnij{' '}
            <Link
              sx={{ cursor: 'pointer' }}
              className={'bold'}
              onClick={() => navigate('/profile/data')}
            >
              dane podstawowe
            </Link>
            , aby ją aktywować.
          </p>
        </PageContent>
      )}
      {previewUrl && (
        <>
          <PageContent>
            Adres Twojej wizytówki:{' '}
            <Link href={previewUrl} target={'_blank'} className={'bold'}>
              {previewUrl}
            </Link>
          </PageContent>
          <iframe
            src={previewUrl}
            style={{
              width: '100%',
              height: '500px',
              border: `solid 1px ${Color.lightGrey}`,
              overflowX: 'scroll',
            }}
          ></iframe>
        </>
      )}
    </>
  );
};
export default ProfilePreview;
