import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useInfo } from '../../providers/info/info.context';
import { Package } from '../../types/Package';
import http from '../../api/http';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import AddIcon from '@mui/icons-material/Add';
import { usePackageDelete } from '../../hooks/use-package-delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PackagesList = () => {
  const [trainingPackages, setPackages] = useState<Package[]>([]);
  const { setLoading, openModal } = useInfo();
  const navigate = useNavigate();
  const onPackageDelete = usePackageDelete(() => loadPackages());

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = () => {
    setLoading(true);
    http()
      .get('/packages')
      .then(({ data }) => {
        setPackages(data);
      })
      .finally(() => setLoading(false));
  };

  const onPackageCreate = () => {
    openModal({
      type: 'package_form',
      onChange: () => loadPackages(),
      params: {},
    });
  };

  const onPackageUpdate = (packageId: number) => {
    openModal({
      type: 'package_form',
      onChange: () => loadPackages(),
      params: {
        packageId,
      },
    });
  };

  const onPackageRemove = (packageId: number) => onPackageDelete(packageId);

  return (
    <>
      <PageHeader
        label={'Pakiety treningowe'}
        onPrimaryButtonClick={() => onPackageCreate()}
        primaryButtonIcon={<AddIcon />}
      />
      {trainingPackages.length === 0 && (
        <PageContent>Nie dodano jeszcze żadnego pakietu.</PageContent>
      )}
      {trainingPackages.length > 0 && (
        <Table>
          <TableBody>
            {trainingPackages.map((trainingPackage, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>{trainingPackage.name}</TableCell>
                  <TableCell width={24}>
                    {trainingPackage.isPublic ? (
                      <VisibilityIcon color={'success'} />
                    ) : (
                      <VisibilityOffIcon color={'error'} />
                    )}
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() =>
                        navigate(`/packages/view/${trainingPackage.id}`)
                      }
                    >
                      <PageviewIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => onPackageUpdate(trainingPackage.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => onPackageRemove(trainingPackage.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export default PackagesList;
