import React from 'react';
import { Box, Link } from '@mui/material';

const ErrorPage = ({ code = 404 }: { code?: number }) => {
  return (
    <Box sx={{ p: 5, textAlign: 'center' }}>
      <Link href={'/'}>
        <img src={'/img/img_logo_twojtrener.png'} height={60} alt={'twojtrener.pl'} />
      </Link>
      <br />
      <br />
      <h1>{code}</h1>
      <br />
      <h3>Nie znaleziono strony</h3>
      <Box sx={{ pt: 5, mb: 5 }}>
        <Link href={'/'}>Wróć do strony głównej</Link>
      </Box>
    </Box>
  );
};
export default ErrorPage;
