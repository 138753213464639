import React from 'react';
import AuthProvider from './providers/auth/AuthProvider';
import InfoProvider from './providers/info/InfoProvider';
import AppRoutes from './pages/AppRoutes';
import { createTheme, ThemeProvider } from '@mui/material';
import { Color } from './styles/colors';
import PWAInstallationPrompt from './components/PWAInstallationPrompt/PWAInstallationPrompt';
import { HelmetProvider } from 'react-helmet-async';

const ttTheme = createTheme({
  palette: {
    primary: {
      main: Color.ttViolet,
      dark: Color.ttVioletDark,
      light: Color.ttVioletLight,
      contrastText: Color.white,
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={ttTheme}>
      <HelmetProvider>
        <InfoProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </InfoProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};
export default App;
