import http from './http';

export const apiLoadSports = (ofTrainer: boolean = false) => {
  return http()
    .get(`/meta/sports${ofTrainer ? '?selected=true' : ''}`)
    .then(({ data }) => {
      return data || [];
    });
};

export const apiLoadCities = () => {
  return http()
    .get('/meta/cities')
    .then(({ data }) => {
      return data || [];
    });
};
