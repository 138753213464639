import React from 'react';
import { Color } from '../../styles/colors';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AppBar from '@mui/material/AppBar/AppBar';
import IconButton from '@mui/material/IconButton';
import ActionsButton, { ActionItem } from '../ActionsButton/ActionsButton';
import { useNavigate } from 'react-router-dom';

export type PageHeaderButton = {
  key: string;
  onClick: () => void;
  icon: React.ReactElement;
  tooltip?: string;
};

const PageHeader = React.memo(
  ({
    label,
    onBackButtonClick,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onThirdButtonClick,
    primaryButtonIcon,
    secondaryButtonIcon,
    thirdButtonIcon,
    actions = [],
  }: {
    label: string;
    onBackButtonClick?: () => void;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
    onThirdButtonClick?: () => void;
    primaryButtonIcon?: JSX.Element;
    secondaryButtonIcon?: JSX.Element;
    thirdButtonIcon?: JSX.Element;
    actions?: ActionItem[];
  }) => {
    const navigate = useNavigate();

    return (
      <AppBar
        position={'static'}
        sx={{
          backgroundColor: Color.lightGrey,
          color: Color.black,
          fontWeight: 600,
          fontSize: '18px',
          marginBottom: '10px',
          '.MuiBox-root': {
            cursor: 'pointer',
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            button: {
              marginLeft: '5px',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {onBackButtonClick && (
              <Box
                sx={{ display: 'flex', alignItems: 'center', width: '30px' }}
                onClick={() => onBackButtonClick && onBackButtonClick()}
                // onClick={() => navigate(-1)}
              >
                <KeyboardArrowLeftIcon />
              </Box>
            )}
            <span>{label}</span>
          </Box>
          <Box>
            {onPrimaryButtonClick && (
              <IconButton
                color={'primary'}
                size={'small'}
                onClick={onPrimaryButtonClick}
              >
                {primaryButtonIcon}
              </IconButton>
            )}
            {onSecondaryButtonClick && (
              <IconButton
                color={'primary'}
                size={'small'}
                onClick={onSecondaryButtonClick}
              >
                {secondaryButtonIcon}
              </IconButton>
            )}
            {onThirdButtonClick && (
              <IconButton
                color={'primary'}
                size={'small'}
                onClick={onThirdButtonClick}
              >
                {thirdButtonIcon}
              </IconButton>
            )}
            {actions.length > 0 && <ActionsButton actions={actions} />}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
);
export default PageHeader;
