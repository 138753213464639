import React, { useCallback, useEffect, useState } from 'react';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useInfo } from '../../providers/info/info.context';
import Former from '../../components/Former/Former';
import { FieldType } from '../../types/FieldType';
import http from '../../api/http';
import { EventLocation, initialEventLocation } from '../../types/EventLocation';
import Layout from '../../components/Layout/Layout';
import { getApiValidationErrors } from "../../utils";

export const eventLocationFields: FieldType[] = [
  {
    key: 'name',
    type: 'text',
    label: 'Nazwa miejsca (adres)',
    required: true,
  },
];

const EventLocationForm = () => {
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const { setLoading, showMessage } = useInfo();
  const [pageTitle, setPageTitle] = useState<string>('Dodaj miejsce treningu');
  const [eventLocationData, setEventLocationData] =
    useState<EventLocation>(initialEventLocation);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setFormFields(eventLocationFields);
    if (params.eventLocationId) {
      setPageTitle('Edycja miejsca treningu');
      loadEventLocation(params.eventLocationId);
    }
  }, [params.eventLocationId]);

  const loadEventLocation = useCallback((eventLocationId: string) => {
    setLoading(true);
    http()
      .get(`/event-locations/${eventLocationId}`)
      .then(({ data }) => {
        setEventLocationData({ ...data });
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = (data: object) => {
    setLoading(true);
    setFormErrors({});

    (params.eventLocationId
      ? http().put(`/event-locations/${params.eventLocationId}`, data)
      : http().post('/event-locations', data)
    )
      .then(() => {
        showMessage('Miejsce treningu zostało zapisane');
        navigate('/event-locations');
      })
      .catch((error) => {
        setFormErrors(getApiValidationErrors(error));
        showMessage('Popraw błędy w formularzu', 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout variant={'full'}>
      <PageHeader
        label={pageTitle}
        onBackButtonClick={() => navigate('/event-locations')}
      />
      <PageContent>
        <Former
          fieldsConfig={formFields}
          formErrors={formErrors}
          defaultValue={eventLocationData}
          onSubmit={onSubmit}
        />
      </PageContent>
    </Layout>
  );
};
export default EventLocationForm;
