import React, { useEffect } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { DialogActionsStyled } from '../modal.styles';
import { useAuth } from '../../providers/auth/auth.context';
import http from '../../api/http';
import { useForm } from 'react-hook-form';
import { useInfo } from '../../providers/info/info.context';
import { getApiValidationErrors } from "../../utils";

const MessageModal = ({
  messageId,
  userName,
  onCancel,
}: {
  messageId: string;
  userName: string;
  onCancel: (reload?: boolean) => void;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const { token } = useAuth();
  const { setLoading, showMessage } = useInfo();

  useEffect(() => {
    reset({ content: '' });
  }, []);

  const createMessage = (formData: any) => {
    setLoading(true);
    http()
      .post(`/messages/${messageId}`, formData)
      .then(() => {
        showMessage('Wiadomość została wysłana', 'success');
        onCancel(true);
      })
      .catch((errorResponse) => {
        const errors = getApiValidationErrors(errorResponse);
        Object.keys(errors).forEach((key) => {
          setError(key, { message: errors[key] });
        });
        showMessage('Nie można wysłać wiadomości', 'error');
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data: any) => {
    createMessage(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Wyślij wiadomość do {userName}</DialogTitle>
        <DialogContent>
          <Box sx={{ marginBottom: '20px' }}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel>Treść wiadomości</FormLabel>
              <TextField
                {...register('content', {
                  required: 'Podaj treść wiadomości',
                  minLength: {
                    value: 10,
                    message: 'Wpisz co najmniej 10 znaków',
                  },
                })}
                multiline={true}
                rows={5}
                variant={'outlined'}
                size={'small'}
                error={!!errors.content}
                sx={{
                  minWidth: {
                    sm: '400px',
                    md: '500px',
                  },
                }}
              />
            </FormControl>
            {errors.content && (
              <FormHelperText error={true}>
                <>{errors.content?.message}</>
              </FormHelperText>
            )}
          </Box>
        </DialogContent>
        <DialogActionsStyled>
          <Button type={'submit'} variant={'contained'} size={'small'}>
            Wyślij
          </Button>
          <Button
            onClick={() => onCancel()}
            variant={'outlined'}
            size={'small'}
          >
            Zamknij
          </Button>
        </DialogActionsStyled>
      </form>
    </>
  );
};
export default MessageModal;
