import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import http from '../../api/http';
import { Box, Button } from '@mui/material';
import { useInfo } from '../../providers/info/info.context';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { useAuth } from '../../providers/auth/auth.context';

const ConfirmEmailPage = () => {
  const { handleToken } = useAuth();
  const { showMessage, setLoading } = useInfo();
  const { hash } = useParams();

  useEffect(() => {
    onConfirm();
  }, [hash]);

  const onConfirm = () => {
    setLoading(true);
    http()
      .post('/auth/confirm-email/', { hash })
      .then(({ data }) => {
        if (data && data.token) {
          showMessage('Twoje konto zostało aktywowane!', 'success');
          handleToken(data.token, '/profile');
        }
      })
      .catch(() => {
        showMessage(
          'Wystąpił błąd podczas aktywacji konta, spróbuj ponownie',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout type={'register'}>
      <>
        <div className={'text-center'}>
          <Button onClick={onConfirm} variant={'contained'} size={'large'}>
            Aktywuj konto
          </Button>
        </div>
      </>
    </AuthLayout>
  );
};
export default ConfirmEmailPage;
