import React, { useEffect } from 'react';
import { FieldType } from '../../types/FieldType';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Autocomplete,
  createFilterOptions,
  FormGroup,
  Checkbox,
  Box,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FormerFieldPreview from './FormerFieldPreview';
import FormerLabel from './FormerLabel';

const filter = createFilterOptions<any>();

const Former = ({
  isReadOnly = false,
  fieldsConfig = [],
  defaultValue,
  formErrors,
  onSubmit,
  onChange,
  hideButtons = false,
  submitTrigger = 0,
  saveButtonLabel,
  mb = 2,
}: {
  isReadOnly?: boolean;
  fieldsConfig: FieldType[];
  defaultValue: any;
  formErrors?: any;
  onSubmit?: (data: any) => void;
  onChange?: (data: any, field?: string) => void;
  hideButtons?: boolean;
  submitTrigger?: number;
  saveButtonLabel?: string;
  mb?: number;
}) => {
  const { register, handleSubmit, reset, control, watch, getValues } =
    useForm();

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      const subscription = watch((data, { name }) => onChange(data, name));
      return () => subscription.unsubscribe();
    }
  }, [onChange]);

  useEffect(() => {
    if (submitTrigger > 0) {
      onSubmit && onSubmit(getValues());
    }
  }, [submitTrigger]);

  return (
    <>
      {!isReadOnly && (
        <form onSubmit={handleSubmit(onSubmit ? onSubmit : () => false)}>
          <Grid container spacing={2} className={'Former'}>
            {fieldsConfig.map((fieldItem) => {
              if (fieldItem.isReadonly) {
                return (
                  <Grid
                    item
                    md={fieldItem.columnSize || 12}
                    sm={fieldItem.columnSize || 12}
                    xs={fieldItem.columnSize || 12}
                    key={fieldItem.key}
                  >
                    <FormerFieldPreview
                      label={fieldItem.label}
                      value={defaultValue[fieldItem.key]}
                    />
                  </Grid>
                );
              }
              return (
                <Grid
                  item
                  md={fieldItem.columnSize || 12}
                  sm={fieldItem.columnSize || 12}
                  xs={fieldItem.columnSize || 12}
                  key={fieldItem.key}
                >
                  <FormControl sx={{ width: '100%', mb: 0 }}>
                    <FormerLabel fieldItem={fieldItem} />

                    {['text', 'number', 'email', 'date', 'time'].includes(
                      fieldItem.type
                    ) && (
                      <TextField
                        {...register(fieldItem.key)}
                        variant={'outlined'}
                        size={'small'}
                        autoComplete={'off'}
                        type={fieldItem.type}
                        error={
                          !!formErrors?.[fieldItem.errorKey || fieldItem.key]
                        }
                      />
                    )}

                    {['textarea'].includes(fieldItem.type) && (
                      <TextField
                        {...register(fieldItem.key)}
                        variant={'outlined'}
                        size={'small'}
                        autoComplete={'off'}
                        multiline={true}
                        rows={5}
                        error={
                          !!formErrors?.[fieldItem.errorKey || fieldItem.key]
                        }
                      />
                    )}

                    {fieldItem.type === 'boolean' && (
                      <Controller
                        render={({ field }) => (
                          <RadioGroup {...field} row>
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Tak"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Nie"
                            />
                          </RadioGroup>
                        )}
                        name={fieldItem.key}
                        control={control}
                      />
                    )}

                    {fieldItem.type === 'select' && (
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            variant={'outlined'}
                            size={'small'}
                            error={
                              !!formErrors?.[
                                fieldItem.errorKey || fieldItem.key
                              ]
                            }
                          >
                            {Array.isArray(fieldItem.items) &&
                              fieldItem.items.map((item, k) => {
                                return (
                                  <MenuItem
                                    key={k}
                                    value={item.id}
                                    disabled={item.disabled}
                                  >
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                        name={fieldItem.key}
                        control={control}
                      />
                    )}

                    {fieldItem.type === 'checkbox' &&
                      Array.isArray(fieldItem.items) && (
                        <>
                          {Array.isArray(fieldItem.items) &&
                            fieldItem.items.map((checkbox) => {
                              return (
                                <FormControlLabel
                                  {...register(fieldItem.key)}
                                  key={checkbox.id}
                                  control={
                                    <Checkbox
                                      value={checkbox?.value}
                                      checked={defaultValue[
                                        fieldItem.key
                                      ].includes(checkbox?.value)}
                                    />
                                  }
                                  label={checkbox.name}
                                />
                              );
                            })}
                        </>
                      )}

                    {fieldItem.type === 'autocomplete' && (
                      <Controller
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Autocomplete
                            {...register(name)}
                            ref={ref}
                            size={'small'}
                            onChange={(ev, value) => onChange(value)}
                            noOptionsText={'Brak wyników'}
                            clearOnBlur={false}
                            clearOnEscape={false}
                            value={value}
                            options={
                              (Array.isArray(fieldItem.items) &&
                                fieldItem.items) ||
                              []
                            }
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some(
                                (option) => inputValue === option.title
                              );

                              if (
                                fieldItem.newOptionPossible &&
                                inputValue !== '' &&
                                !isExisting
                              ) {
                                filtered.push({
                                  id: 0,
                                  newName: inputValue,
                                  name: `Dodaj "${inputValue}"`,
                                  isNew: true,
                                });
                              }
                              return filtered;
                            }}
                            getOptionLabel={(option: any) => {
                              if (typeof option === 'number') {
                                return '';
                              }
                              return option.id === 0
                                ? option.newName
                                : option.name;
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            renderOption={(props: any, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  <span>{option.name}</span>
                                </li>
                              );
                            }}
                          />
                        )}
                        name={fieldItem.key}
                        control={control}
                      />
                    )}

                    {formErrors &&
                      formErrors[fieldItem.errorKey || fieldItem.key] && (
                        <FormHelperText error={true}>
                          {formErrors[fieldItem.errorKey || fieldItem.key]}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              );
            })}

            {!hideButtons && (
              <Grid item md={12} sm={12} xs={12}>
                <FormControl>
                  <Button variant={'contained'} type={'submit'}>
                    {saveButtonLabel || 'Zapisz zmiany'}
                  </Button>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </form>
      )}
      {isReadOnly && (
        <>
          {fieldsConfig.map((fieldItem) => {
            return (
              <div key={fieldItem.key}>
                <FormerFieldPreview
                  label={fieldItem.label}
                  value={defaultValue[fieldItem.key]}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default Former;
