import React from 'react';
import { FieldType } from '../../../types/FieldType';
import { Dict } from '../../../types/Dict';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import PreviewIcon from '@mui/icons-material/Preview';
import { daysNames } from '../../../config/constants';

export const trainingVisibilities: Dict[] = [
  {
    id: 0,
    name: 'Prywatny (widoczny tylko dla trenera)',
    shortName: 'prywatny',
    icon: <VisibilityOffIcon />,
  },
  {
    id: 1,
    name: 'Publiczny (widoczny na twojtrener.pl)',
    shortName: 'publiczny',
    icon: <VisibilityIcon />,
  },
  // {
  //   id: 2,
  //   name: 'Dla klientów (widoczny dla klientów na twojtrener.pl)',
  //   shortName: 'dla klientów',
  //   icon: <PreviewIcon />,
  // },
];

export const trainingFields: FieldType[] = [
  {
    key: 'serviceId',
    type: 'select',
    label: 'Usługa',
    labelDescription:
      'Na podstawie usługi wypełnimy automatycznie pola treningu. Możesz je nadal zmienić lub dowolnie zaplanować trening. Uwaga! Zmiana usługi nadpisze dane w polach, które wcześniej wypełniono.',
    required: true,
  },
  { key: 'name', type: 'text', label: 'Nazwa treningu', required: true },
  {
    key: 'startDate',
    type: 'date',
    label: 'Data rozpoczęcia',
    required: true,
    columnSize: 6,
  },
  {
    key: 'startTime',
    type: 'time',
    label: 'Godzina',
    required: true,
    columnSize: 6,
  },
  {
    key: 'time',
    type: 'number',
    label: 'Czas trwania (min.)',
    required: true,
    columnSize: 6,
  },
  {
    key: 'price',
    type: 'number',
    label: 'Cena (zł)',
    columnSize: 6,
  },
  {
    key: 'visibility',
    type: 'select',
    label: 'Widoczność',
    required: true,
    columnSize: 6,
  },
  {
    key: 'maxParticipantsAmount',
    type: 'number',
    label: 'Ilość uczestników',
    required: true,
    columnSize: 6,
  },
  {
    key: 'EventLocation',
    type: 'autocomplete',
    label: 'Lokalizacja',
    labelDescription:
      'Wybierz miejsce treningu z listy lub dodaj własne (adres)',
    labelDescriptionOpened: true,
    required: true,
    errorKey: 'EventLocation',
    newOptionPossible: true,
  },
  {
    key: 'breakTime',
    type: 'number',
    label: 'Przerwa po treningu (min.)',
    required: true,
    labelDescription: 'Wymagane przy dodawaniu serii treningów',
    labelDescriptionOpened: true,
  },
  {
    key: 'description',
    type: 'textarea',
    label: 'Opis treningu',
    required: false,
  },
  // {
  //   key: 'sportId',
  //   type: 'select',
  //   label: 'Dyscyplina sportowa',
  // },
];

export const repeatingEventFields: FieldType[] = [
  {
    key: 'startDate',
    type: 'date',
    label: 'Data początkowa',
    required: true,
    columnSize: 6,
  },
  {
    key: 'endDate',
    type: 'date',
    label: 'Data końcowa',
    required: true,
    columnSize: 6,
  },
  {
    key: 'startTime',
    type: 'time',
    label: 'Godzina OD',
    required: true,
    columnSize: 6,
  },
  {
    key: 'endTime',
    type: 'time',
    label: 'Godzina DO',
    required: true,
    columnSize: 6,
  },
  {
    key: 'weekDays',
    type: 'checkbox',
    label: 'Dni tygodnia',
    required: true,
    items: daysNames,
  },
];
