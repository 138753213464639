import React from 'react';
import {
  Box,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { EventClient, PaymentMethod } from '../../types/EventClient';
import { ClientPackage } from '../../types/ClientPackage';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { paymentMethods } from './EventClientForm';

const EventClientPreview = ({
  eventClient,
  clientPackages,
}: {
  eventClient: EventClient;
  clientPackages: ClientPackage[];
}) => {
  if (!eventClient) {
    return <></>;
  }

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Table sx={{ td: { padding: '5px' } }}>
        <TableBody>
          <TableRow>
            <TableCell>Obecność</TableCell>
            <TableCell>
              {eventClient?.isPresent ? (
                <CheckBoxIcon color={'success'} />
              ) : (
                <DisabledByDefaultIcon color={'error'} />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Wykonanie</TableCell>
            <TableCell>
              <Rating
                defaultValue={eventClient?.performanceRate}
                readOnly={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Zaangażowanie</TableCell>
            <TableCell>
              <Rating
                defaultValue={eventClient?.commitmentRate}
                readOnly={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Płatność</TableCell>
            <TableCell>
              {paymentMethods.find((pm) => pm.id === eventClient?.paymentMethod)
                ?.name || '- - - brak - - -'}
            </TableCell>
          </TableRow>
          {eventClient?.paymentMethod === PaymentMethod.package && (
            <TableRow>
              <TableCell>Pakiet</TableCell>
              <TableCell>
                {clientPackages.find(
                  (cp) => cp.id === eventClient?.clientPackageId
                )?.name || '- - - brak - - -'}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>Komentarz</TableCell>
            <TableCell>{eventClient?.comment || '- - - brak - - -'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
export default EventClientPreview;
