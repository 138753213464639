import React from 'react';
import src from '../../assets/icons/ico_google.svg';

const GoogleIcon = () => {
  return (
    <>
      <img src={src} alt="Google logo" width={20} height={20} />
    </>
  );
};
export default GoogleIcon;
