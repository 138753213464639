export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

export const initialUser: User = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
};
