import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import http from '../../api/http';
import { daysNames } from '../../config/constants';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Color } from '../../styles/colors';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Availability } from '../../types/Availability';
import InfoIcon from '@mui/icons-material/Info';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import { useInfo } from '../../providers/info/info.context';

const AvailabilitiesList = () => {
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [showAvailabilityInfo, setShowAvailabilityInfo] = useState(
    localStorage.getItem('showAvailabilityInfo') !== 'false'
  );
  const [isSaving, setIsSaving] = useState(false);
  const { setLoading, showMessage } = useInfo();

  useEffect(() => {
    loadAvailabilities();
  }, []);

  const loadAvailabilities = () => {
    setLoading(true);
    http()
      .get('/availabilities')
      .then(({ data }: { data: Availability[] }) => {
        if (Array.isArray(data) && data.length > 0) {
          setAvailabilities(
            data.map((item) => ({
              ...item,
              tmpId: Math.random() * 100,
            }))
          );
        } else {
          generateEmptyHours();
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const generateEmptyHours = () => {
    const emptyAvailabilities = [];
    for (let d = 1; d <= 5; d++) {
      emptyAvailabilities.push({
        id: Math.random(),
        day: d,
        startTime: '08:00',
        endTime: '16:00',
      });
    }
    setAvailabilities(emptyAvailabilities);
  };

  const addNewOpeningHour = () => {
    const newAvailabilities = [...availabilities];
    newAvailabilities.push({
      id: Math.random(),
      day: 1,
      startTime: '08:00',
      endTime: '16:00',
    });
    setAvailabilities(newAvailabilities);
  };

  const onFieldChange = (field: string, val: any, index: number) => {
    const newAvailabilities: Availability[] = [...availabilities];
    if (field === 'timeStart') {
      newAvailabilities[index].startTime = val;
    } else if (field === 'timeEnd') {
      newAvailabilities[index].endTime = val;
    } else if (field === 'day') {
      newAvailabilities[index].day = val;
    }
    setAvailabilities(newAvailabilities);
  };

  const getOpeningValue = (
    opening: Availability,
    timeType: 'startTime' | 'endTime'
  ) => {
    return timeType === 'startTime' ? opening.startTime : opening.endTime;
  };

  const submitChanges = (ev: any) => {
    ev.preventDefault();
    setIsSaving(true);

    const promises = [];
    for (let p = 0; p < availabilities.length; p++) {
      if (availabilities[p].uid) {
        promises.push(
          http().patch(
            `/availabilities/${availabilities[p].uid}`,
            availabilities[p]
          )
        );
      } else {
        promises.push(http().post('/availabilities', availabilities[p]));
      }
    }
    Promise.all(promises)
      .then(() => {
        showMessage('Podane dostępności zostały zapisane.', 'success');
        loadAvailabilities();
      })
      .catch((errors) => {
        showMessage('Wystąpił błąd w zapisie, spróbuj ponownie.', 'error');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const deleteOpeningHour = (opening: Availability) => {
    if (window.confirm('Czy na pewno chcesz usunąć tę dostępność?')) {
      const newAvailabilities: Availability[] = availabilities.filter(
        (item) => item.uid !== opening.uid
      );
      if (opening.uid) {
        setLoading(true);
        http()
          .delete(`/availabilities/${opening.uid}`)
          .then(() => {
            setAvailabilities(newAvailabilities);
          })
          .catch(() => {
            showMessage(
              'Wystąpił błąd podczas usuwania, spróbuj ponownie.',
              'error'
            );
          })
          .finally(() => setLoading(false));
      } else {
        setAvailabilities(newAvailabilities);
      }
    }
  };

  return (
    <>
      <PageHeader
        label={'Dostępność'}
        onPrimaryButtonClick={() => {
          localStorage.setItem('showAvailabilityInfo', 'true');
          setShowAvailabilityInfo(true);
        }}
        primaryButtonIcon={<InfoIcon />}
      />
      <PageContent>
        {showAvailabilityInfo && (
          <Alert
            color={'info'}
            className="mb30"
            onClose={() => {
              localStorage.setItem('showAvailabilityInfo', 'false');
              setShowAvailabilityInfo(false);
            }}
          >
            Poniżej możesz wypełnić godziny dostępności dla poszczególnych dni
            tygodnia, które będą użyte do automatycznego wygenerowania
            kalendarza treningów dla klientów. Możesz dodać kilka przedziałów
            czasowych dla jednego dnia używając przyciku "Dodaj godziny". Jeżeli
            dany przedział czasowy nie został jeszcze zapisany pojawi się przy
            nim ikonka{' '}
            <FiberNewIcon fontSize={'medium'} sx={{ color: Color.ttViolet }} />
          </Alert>
        )}

        {availabilities.length > 0 && (
          <Box>
            {availabilities.map((opening: Availability, key: number) => {
              return (
                <Grid key={key} container spacing={2} className="mb20">
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Dzień tygodnia</InputLabel>
                      <Select
                        onChange={(ev) =>
                          onFieldChange('day', ev.target.value, key)
                        }
                        disabled={isSaving}
                        required={true}
                        fullWidth
                        label="Dzień tygodnia"
                        size="small"
                        value={opening.day}
                      >
                        {daysNames.map((day) => {
                          return (
                            <MenuItem value={day.value} key={day.id}>
                              {day.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <TextField
                      type="time"
                      label={'Początek'}
                      defaultValue={getOpeningValue(opening, 'startTime')}
                      onChange={(event) =>
                        onFieldChange('timeStart', event.target.value, key)
                      }
                      disabled={isSaving}
                      fullWidth
                      size={'small'}
                    />
                    {/*{errors.name && <FormItemError>{errors.name}</FormItemError>}*/}
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <TextField
                      type="time"
                      label={'Koniec'}
                      defaultValue={getOpeningValue(opening, 'endTime')}
                      onChange={(event) =>
                        onFieldChange('timeEnd', event.target.value, key)
                      }
                      disabled={isSaving}
                      fullWidth
                      size={'small'}
                    />
                    {/*{errors.name && <FormItemError>{errors.name}</FormItemError>}*/}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <AvailabilityActions>
                      <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => deleteOpeningHour(opening)}
                        variant={'outlined'}
                        size={'small'}
                      >
                        Usuń
                      </Button>
                      {!opening.uid && (
                        <FiberNewIcon
                          fontSize="large"
                          sx={{ color: Color.ttViolet }}
                        />
                      )}
                    </AvailabilityActions>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item md={3}>
            <Button
              disabled={isSaving}
              variant="outlined"
              fullWidth
              onClick={addNewOpeningHour}
              startIcon={<AddIcon />}
            >
              Dodaj godziny
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
            {isSaving && <>LOADING...</>}

            {!isSaving && (
              <Button
                disabled={isSaving}
                onClick={submitChanges}
                fullWidth
                variant="contained"
              >
                Zapisz zmiany
              </Button>
            )}
          </Grid>
        </Grid>
      </PageContent>
    </>
  );
};
export default AvailabilitiesList;

const AvailabilityActions = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  button: { marginRight: '30px' },
});
