export type Package = {
  id: number;
  name: string;
  description?: string;
  trainingsCount: number;
  price: number;
  trainerId?: number;
  isArchived?: boolean;
  isPublic?: boolean;
};
export const initialPackage: Package = {
  id: 0,
  name: '',
  description: '',
  isArchived: false,
  trainingsCount: 1,
  price: 0,
  isPublic: true,
};
