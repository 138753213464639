import React, { useEffect, useState } from 'react';
import { Client } from '../../types/Client';
import { Box, Card, CardContent, FormLabel, IconButton } from '@mui/material';
import { Color } from '../../styles/colors';
import { Training } from '../../types/Training';
import ClientSelect from '../ClientSelect/ClientSelect';
import EventClientForm from '../EventClientForm/EventClientForm';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useNavigate } from 'react-router-dom';

const TrainingClients = ({
  clients,
  training,
  header,
  addClientInPreviewOnly = false,
  onChange,
  onModalCancel,
}: {
  clients: Client[];
  training: Training;
  header?: string;
  addClientInPreviewOnly?: boolean;
  onChange?: (clients: Client[]) => void;
  onModalCancel?: () => void;
}) => {
  const [trainingClients, setTrainingClients] = useState<Client[]>(clients);
  const navigate = useNavigate();

  useEffect(() => {
    setTrainingClients(clients);
  }, [clients]);

  const onClientAdded = (newClient: Client) => {
    const newTrainingClients = [...trainingClients];
    newTrainingClients.push(newClient);
    setTrainingClients(newTrainingClients);
    onChange && onChange(newTrainingClients);
  };

  return (
    <Box>
      <FormLabel>{header || 'Klienci:'}</FormLabel>
      {trainingClients.length > 0 &&
        trainingClients.map((client) => {
          return (
            <Card key={client.id} variant={'outlined'} className="mb20">
              <CardContent>
                <strong>
                  {client.firstName && client.lastName ? (
                    <span>
                      {client.firstName} {client.lastName}
                    </span>
                  ) : (
                    <>- - -</>
                  )}
                  <IconButton
                    color={'secondary'}
                    onClick={() => {
                      navigate(`/clients/view/${client.id}`);
                      onModalCancel && onModalCancel();
                    }}
                  >
                    <InsertLinkIcon />
                  </IconButton>
                </strong>
                <br />
                tel.: <strong>{client.phone || '- - -'}</strong>
                <br />
                e-mail: <strong>{client.email || '- - -'}</strong>
                {!addClientInPreviewOnly && client.EventClient && (
                  <>
                    <br />
                    <EventClientForm
                      clientId={client.id}
                      eventId={training.id}
                      eventClient={client.EventClient}
                      readOnly={addClientInPreviewOnly}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          );
        })}
      {trainingClients.length === 0 && (
        <>
          <p>Brak uczestników treningu</p>
          {training.status !== 'finished' && !addClientInPreviewOnly && (
            <ClientSelect trainingId={training?.id} onSubmit={onClientAdded} />
          )}
          {addClientInPreviewOnly && (
            <p>Aby dodać klienta przejdź do podglądu treningu</p>
          )}
        </>
      )}
    </Box>
  );
};
export default TrainingClients;
