import React, { useState } from 'react';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from '../modal.styles';
import PackageForm from '../../pages/PackagesPage/PackageForm';
import { Package } from '../../types/Package';

const PackageFormModal = ({
  onCancel,
  onChange,
  params,
}: {
  onCancel: () => void;
  onChange: (data: any) => void;
  params?: any;
}) => {
  const [submitFormTrigger, setSubmitFormTrigger] = useState<number>(0);

  const handleOnSubmit = () => {
    setSubmitFormTrigger(submitFormTrigger + 1);
  };

  const onPackageDataChanged = (data: Package) => {
    onChange(data);
  };

  return (
    <>
      <DialogTitle>
        {params.packageId ? 'Edycja pakietu' : 'Dodaj pakiet'}
      </DialogTitle>
      <DialogContent>
        <PackageForm
          packageId={params.packageId}
          submitFormTrigger={submitFormTrigger}
          onFormSubmit={onPackageDataChanged}
        />
      </DialogContent>
      <DialogActionsStyled>
        <Button onClick={() => onCancel()} variant={'outlined'}>
          Anuluj
        </Button>
        <Button
          onClick={handleOnSubmit}
          variant={'contained'}
          color={'primary'}
        >
          Zapisz
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default PackageFormModal;
