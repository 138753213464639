import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import { Client, initialClient } from '../../types/Client';
import http from '../../api/http';
import Former from '../../components/Former/Former';
import { useInfo } from '../../providers/info/info.context';
import { clientFields } from './ClientForm';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useClientDelete } from '../../hooks/use-client-delete';
import ClientPackages from '../../components/ClientPackages/ClientPackages';

const ClientView = () => {
  const { setLoading, openModal } = useInfo();
  const [clientData, setClientData] = useState<Client>(initialClient);
  const params = useParams();
  const onClientDelete = useClientDelete();

  useEffect(() => {
    if (params.clientId) {
      loadClient(parseInt(params.clientId, 10));
    }
  }, []);

  const loadClient = (clientId: number) => {
    setLoading(true);
    http()
      .get(`/clients/${clientId}`)
      .then(({ data }) => {
        setClientData(data);
      })
      .finally(() => setLoading(false));
  };

  const handleOnDelete = (clientId: number) => onClientDelete(clientId);

  const handleOnEdit = (clientId: number) => {
    openModal({
      type: 'client_form',
      onChange: () => loadClient(clientId),
      params: {
        clientId,
      },
    });
  };

  return (
    <>
      <PageHeader
        label={'Dane klienta'}
        primaryButtonIcon={<EditIcon />}
        onPrimaryButtonClick={() => handleOnEdit(clientData.id)}
        secondaryButtonIcon={<DeleteIcon />}
        onSecondaryButtonClick={() => handleOnDelete(clientData.id)}
      />
      <PageContent>
        <Former
          fieldsConfig={clientFields}
          defaultValue={clientData}
          isReadOnly={true}
        />
        {clientData.id && <ClientPackages clientId={clientData.id} />}
      </PageContent>
    </>
  );
};
export default ClientView;
