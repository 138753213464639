import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Main = styled(Box)({
  padding: 20,
  width: '100%',
});

export const UserLoggedIn = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const UserName = styled(Box)({
  paddingLeft: 10,
});
