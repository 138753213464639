import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { PageContent } from './ProfilePage.styles';

const ProfileStatistics = () => {
  const navigate = useNavigate();
  const [trainerData] = useOutletContext<any>();

  return (
    <>
      <PageHeader
        label={'Statystyki'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        <Table sx={{ td: { fontSize: 16 } }}>
          <TableBody>
            <TableRow>
              <TableCell>Ilość odsłon wizytówki</TableCell>
              <TableCell>{trainerData.pageViews}</TableCell>
            </TableRow>
            {/*<TableRow>*/}
            {/*  <TableCell>Ilość odsłon nr telefonu</TableCell>*/}
            {/*  <TableCell>{trainerData.phoneViews}</TableCell>*/}
            {/*</TableRow>*/}
            <TableRow>
              <TableCell>Ilość opinii / ocen</TableCell>
              <TableCell>{trainerData.reviewsCount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ocena</TableCell>
              <TableCell>{trainerData.reviewsRate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </PageContent>
    </>
  );
};
export default ProfileStatistics;
