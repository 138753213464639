import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Dict } from '../../types/Dict';
import { EventClient, PaymentMethod } from '../../types/EventClient';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { ClientPackage } from '../../types/ClientPackage';
import styled from '@emotion/styled';
import { Color } from '../../styles/colors';
import EventClientPreview from './EventClientPreview';

export const paymentMethods: Dict[] = [
  { id: PaymentMethod.unpaid, name: 'Brak płatności' },
  { id: PaymentMethod.cash, name: 'Gotówka' },
  { id: PaymentMethod.card, name: 'Karta' },
  { id: PaymentMethod.package, name: 'Pakiet' },
  { id: PaymentMethod.other, name: 'Inne' },
];

const EventClientForm = ({
  clientId,
  eventId,
  eventClient,
  readOnly = false,
}: {
  clientId: number;
  eventId: number;
  eventClient: EventClient;
  readOnly: boolean;
}) => {
  const [eventClientData, setEventClientData] =
    useState<EventClient>(eventClient);
  const [originalEventClientData, setOriginalEventClientData] =
    useState<EventClient>(eventClient);
  const [clientPackages, setClientPackages] = useState<ClientPackage[]>([]);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const { setLoading, showMessage } = useInfo();

  useEffect(() => {
    setEventClientData(eventClient);
    setOriginalEventClientData(eventClient);
    getClientPackages();
  }, []);

  const getClientPackages = () => {
    setLoading(true);
    http()
      .get(`/clients/${clientId}/packages`, { params: { active: true } })
      .then(({ data: packages }) => {
        setClientPackages(packages);
        if (
          packages.length > 0 &&
          eventClientData.paymentMethod === PaymentMethod.package
        ) {
          onDataChange(
            'clientPackageId',
            eventClientData?.clientPackageId || packages[0].id
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onDataChange = (
    fieldType:
      | 'performance'
      | 'commitment'
      | 'comment'
      | 'clientPackageId'
      | 'paymentMethod'
      | 'isPresent',
    value: string
  ) => {
    if (eventClientData) {
      const newEventClientData: EventClient = { ...eventClientData };
      if (fieldType === 'performance') {
        newEventClientData.performanceRate = parseInt(value, 10);
      } else if (fieldType === 'commitment') {
        newEventClientData.commitmentRate = parseInt(value, 10);
      } else if (fieldType === 'comment') {
        newEventClientData.comment = value;
      } else if (fieldType === 'clientPackageId') {
        newEventClientData.clientPackageId = parseInt(value, 10);
      } else if (fieldType === 'paymentMethod') {
        newEventClientData.paymentMethod = parseInt(value, 10) as PaymentMethod;
        if (newEventClientData.paymentMethod !== PaymentMethod.package) {
          newEventClientData.clientPackageId = undefined;
        } else if (clientPackages.length > 0) {
          newEventClientData.clientPackageId = clientPackages[0].id;
        }
      } else if (fieldType === 'isPresent') {
        newEventClientData.isPresent = value !== 'false';
      }
      setEventClientData(newEventClientData);
    }
  };

  const onEventClientUpdate = () => {
    setLoading(true);
    http()
      .put(
        `/trainings/${eventId}/client/${clientId}/${eventClientData?.id}`,
        eventClientData
      )
      .then(({ data }) => {
        showMessage('Zapisano zmiany', 'success');
        setOriginalEventClientData(eventClientData);
        setShowEditForm(false);
      })
      .catch((error) => {
        const errMsg = error?.response?.data;
        showMessage(
          errMsg?.message || errMsg?.msg || 'Wystąpił błąd, spróbuj ponownie',
          'error'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!eventClient) {
    return <></>;
  }

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <Typography
        variant={'h5'}
        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
      >
        Podsumowanie treningu
      </Typography>
      {!showEditForm && (
        <EventClientPreview
          eventClient={originalEventClientData}
          clientPackages={clientPackages}
        />
      )}
      {!showEditForm && !readOnly && (
        <Button variant={'contained'} onClick={() => setShowEditForm(true)}>
          Uzupełnij podsumowanie treningu
        </Button>
      )}
      {showEditForm && (
        <Box
          sx={{
            padding: '10px 15px 20px',
            borderRadius: '4px',
            border: `solid 1px ${Color.grey}`,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <RowElement>
                <FormLabel>Obecność:</FormLabel>
                <RadioGroup
                  row
                  value={eventClientData?.isPresent || false}
                  onChange={(ev) => onDataChange('isPresent', ev.target.value)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Tak"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Nie"
                  />
                </RadioGroup>
              </RowElement>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <RowElement>
                <FormLabel>Ocena wykonania:</FormLabel>
                <Rating
                  value={eventClientData?.performanceRate || 0}
                  onChange={(ev, val) =>
                    onDataChange('performance', (val || 0).toString())
                  }
                  size={'large'}
                />
              </RowElement>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <RowElement>
                <FormLabel>Ocena zaangażowania:</FormLabel>
                <Rating
                  value={eventClientData?.commitmentRate || 0}
                  onChange={(ev, val) =>
                    onDataChange('commitment', (val || 0).toString())
                  }
                  size={'large'}
                />
              </RowElement>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <RowElement>
                <FormLabel>Płatność:</FormLabel>
                <Select
                  label={''}
                  onChange={(ev) =>
                    onDataChange(
                      'paymentMethod',
                      (ev.target.value as PaymentMethod).toString()
                    )
                  }
                  variant={'outlined'}
                  size={'small'}
                  value={eventClientData?.paymentMethod || 0}
                  sx={{ width: '100%' }}
                >
                  {paymentMethods
                    .filter((method) =>
                      method.id === PaymentMethod.package
                        ? clientPackages.length > 0
                        : true
                    )
                    .map((item, k) => {
                      return (
                        <MenuItem key={k} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </RowElement>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              sx={{
                display:
                  eventClientData?.paymentMethod === PaymentMethod.package
                    ? 'flex'
                    : 'none',
              }}
            >
              <RowElement>
                <FormLabel>Pakiet:</FormLabel>
                <Select
                  label={''}
                  onChange={(ev) =>
                    onDataChange('clientPackageId', ev.target.value.toString())
                  }
                  variant={'outlined'}
                  size={'small'}
                  value={eventClientData?.clientPackageId || 0}
                  sx={{ width: '100%' }}
                >
                  {clientPackages.map((item, k) => {
                    return (
                      <MenuItem key={k} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </RowElement>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <RowElement>
                <FormLabel>Komentarz:</FormLabel>
                <TextField
                  variant={'outlined'}
                  size={'small'}
                  autoComplete={'off'}
                  multiline={true}
                  rows={3}
                  sx={{ width: '100%' }}
                  value={eventClientData?.comment}
                  onChange={(ev) =>
                    onDataChange('comment', ev.target.value || '')
                  }
                />
              </RowElement>
            </Grid>
          </Grid>

          <Box sx={{ paddingTop: '20px' }}>
            <Button
              onClick={() => onEventClientUpdate()}
              variant={'contained'}
              sx={{ marginRight: '20px' }}
            >
              Zapisz zmiany
            </Button>
            <Button
              onClick={() => {
                setEventClientData(originalEventClientData);
                setShowEditForm(false);
              }}
              variant={'outlined'}
            >
              Anuluj
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default EventClientForm;

const RowElement = styled(Box)({
  display: 'block',
  width: '100%',
  '.MuiFormLabel-root': { marginRight: '10px', display: 'block' },
});
