import http from '../api/http';
import { useInfo } from '../providers/info/info.context';
import { useNavigate } from 'react-router-dom';

export const useTrainingCancel = () => {
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  const onTrainingCancel = (trainingId: number, cb?: () => void) => {
    if (
      window.confirm(
        'Czy na pewno chcesz anulować trening? Zapisani klienci zostaną z niego usunięci oraz otrzymają powiadomienie na adres e-mail.'
      )
    ) {
      setLoading(true);
      http()
        .get(`/trainings/cancel/${trainingId}`)
        .then(() => {
          showMessage('Trening został anulowany dla klientów', 'success');
          if (cb) {
            cb();
          } else {
            navigate(`/workouts/view/${trainingId}`);
          }
        })
        .catch(() => {
          showMessage('Nie można anulować tego treningu', 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  return onTrainingCancel;
};
