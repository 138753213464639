import React, { useState } from 'react';
import { Button, DialogContent, DialogTitle } from '@mui/material';
import { DialogActionsStyled } from '../modal.styles';
import ClientForm from '../../pages/ClientsPage/ClientForm';
import { Client } from '../../types/Client';

const ClientFormModal = ({
  onCancel,
  onChange,
  params,
}: {
  onCancel: () => void;
  onChange: (data: any) => void;
  params?: any;
}) => {
  const [submitFormTrigger, setSubmitFormTrigger] = useState<number>(0);

  const handleOnSubmit = () => {
    setSubmitFormTrigger(submitFormTrigger + 1);
  };

  const onClientDataChanged = (data: Client) => {
    onChange(data);
  };

  return (
    <>
      <DialogTitle>
        {params.clientId ? 'Edycja klienta' : 'Dodaj klienta'}
      </DialogTitle>
      <DialogContent>
        <ClientForm
          clientId={params.clientId}
          submitFormTrigger={submitFormTrigger}
          onFormSubmit={onClientDataChanged}
        />
      </DialogContent>
      <DialogActionsStyled>
        <Button onClick={() => onCancel()} variant={'outlined'}>
          Anuluj
        </Button>
        <Button
          onClick={handleOnSubmit}
          variant={'contained'}
          color={'primary'}
        >
          Zapisz
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default ClientFormModal;
