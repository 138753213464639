import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PageContent } from './ProfilePage.styles';
import FilesUpload from '../../components/FilesUpload/FilesUpload';
import { Box, Button, Card, CardMedia, Grid, IconButton } from '@mui/material';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { marketplaceUrl } from '../../config/config';
import { Image } from '../../types/Image';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const galleryMaxFilesCount = 10;

const ProfileGallery = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [imagesCountLeft, setImagesCountLeft] =
    useState<number>(galleryMaxFilesCount);
  const { setLoading, showMessage } = useInfo();
  const [trainerData] = useOutletContext<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (trainerData.slug) {
      loadImages(trainerData.slug);
    }
  }, [trainerData]);

  const loadImages = (trainerSlug: string) => {
    setLoading(true);
    http()
      .get(`/files/${trainerSlug}/gallery`)
      .then(({ data }) => {
        setImages(data || []);
        setImagesCountLeft(galleryMaxFilesCount - data.length);
      })
      .finally(() => setLoading(false));
  };

  const removeImage = (imgId: number) => {
    setLoading(true);
    http()
      .delete(`/files/${imgId}`)
      .then(({ data }) => {
        const newImages = images.filter((image: Image) => image.id !== imgId);
        setImages(newImages);
        setImagesCountLeft(galleryMaxFilesCount - newImages.length);
        showMessage('Zdjęcie zostało usunięte', 'success');
      })
      .catch(() => {
        showMessage('Nie można usunąć zdjęcia, spróbuj ponownie', 'error');
      })
      .finally(() => setLoading(false));
  };

  const onFileUploaded = () => {
    loadImages(trainerData.slug);
  };

  return (
    <>
      <PageHeader
        label={'Galeria'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        <Box sx={{ marginBottom: '20px' }}>
          <p>
            Zdjęcia dodane do galerii mogą pokazywać Twoją sylwetkę, metamorfozy
            Twoich klientów lub osiągnięcia sportowe{' '}
            <span className="fs20">🏅</span> Możesz dodać do 10 zdjęć w formacie
            JPG lub PNG.
          </p>
        </Box>
        {images.length > 0 && (
          <Box sx={{ mb: '20px' }}>
            <Grid container>
              {images.map((img, key) => {
                return (
                  <Grid
                    item
                    key={key}
                    md={3}
                    sm={4}
                    xs={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mb: '20px',
                      img: { mb: '10px', width: '60%' },
                    }}
                  >
                    <img
                      src={`${marketplaceUrl}/content/thumb_${img.filename}`}
                    />
                    <Button
                      variant={'outlined'}
                      startIcon={<RemoveCircleIcon />}
                      size={'small'}
                      color={'error'}
                      onClick={() => removeImage(img.id)}
                    >
                      Usuń
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {imagesCountLeft > 0 && (
          <Box sx={{ pt: '20px' }}>
            <FilesUpload
              trainerSlug={trainerData.slug}
              multiFile={true}
              maxFilesCount={imagesCountLeft}
              fileType={20}
              onFileUploaded={onFileUploaded}
            />
          </Box>
        )}
      </PageContent>
    </>
  );
};
export default ProfileGallery;
