import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Link } from '@mui/material';

const ContactPage = () => {
  return (
    <Layout>
      <h2>Pomoc i kontakt</h2>
      <div>
        <p>Chętnie odpowiemy na wszystkie Twoje pytania. Pisz śmiało :)</p>
        <p>
          <Link href={'mailto:kontakt@twojtrener.pl'}>
            kontakt@twojtrener.pl
          </Link>
        </p>
        <br />
        <h2>Regulaminy</h2>
        <div>
          <Link href={'https://twojtrener.pl/regulamin'} target={'_blank'}>
            Regulamin twojtrener.pl
          </Link>
          <br />
          <Link
            href={'https://twojtrener.pl/polityka-prywatnosci'}
            target={'_blank'}
          >
            Polityka prywatności i cookies twojtrener.pl
          </Link>
        </div>
      </div>
    </Layout>
  );
};
export default ContactPage;
