import styled from '@emotion/styled';
import { DialogActions } from '@mui/material';
import { Color } from '../styles/colors';

export const DialogActionsStyled = styled(DialogActions)({
  padding: '20px 24px',
});

export const BtnClose = styled('div')({
  cursor: 'pointer',
  padding: '15px 15px 5px 5px',
  position: 'absolute',
  right: 0,
  color: Color.darkGrey,
});
