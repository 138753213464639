import React from 'react';
import { Button, Chip, DialogContent, DialogTitle } from '@mui/material';
import { Training } from '../../types/Training';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import BlockIcon from '@mui/icons-material/Block';
import styled from '@emotion/styled';
import { DialogActionsStyled } from '../modal.styles';
import TrainingClients from '../../components/TrainingClients/TrainingClients';
import { useNavigate } from 'react-router-dom';
import { useTrainingDelete } from '../../hooks/use-training-delete.hook';
import { useInfo } from '../../providers/info/info.context';
import { useTrainingCancel } from '../../hooks/use-training-cancel.hook';

const QuickTrainingViewModal = ({
  training,
  onCancel,
}: {
  training: Training;
  onCancel: (data?: any) => void;
}) => {
  const { openModal } = useInfo();
  const navigate = useNavigate();
  const onTrainingDelete = useTrainingDelete(() => {
    onCancel(true);
  });
  const onTrainingCancel = useTrainingCancel();

  const onTrainingView = () => {
    navigate({
      pathname: `/workouts/view/${training.id}`,
    });
    onCancel();
  };

  const onTrainingEdit = () => {
    openModal({
      type: 'training_create',
      onChange: () => onCancel(true),
      params: {
        trainingId: training.id,
      },
    });
  };

  const handleOnTrainingCancel = (trainingId: number) => {
    onTrainingCancel(trainingId, () => onCancel(true));
  };

  return (
    <>
      <DialogTitle className="DialogTitlePR">{training.name}</DialogTitle>
      <DialogContent>
        {training.status === 'finished' && (
          <Chip
            sx={{ mb: 1 }}
            label={'Trening Zakończony'}
            color={'error'}
            size={'small'}
          />
        )}
        <p>
          Data: <strong>{training.startDate}</strong>
        </p>
        <p>
          Czas trwania:{' '}
          <strong>
            {training.startTime} - {training.endTime}
          </strong>
        </p>
        <p>
          Cena: <strong>{training.price} zł</strong>
        </p>
        <p>
          Lokalizacja: <strong>{training.EventLocation?.name}</strong>
        </p>
        <p>
          Przerwa po treningu: <strong>{training.breakTime} min.</strong>
        </p>
        {Array.isArray(training.Clients) && (
          <>
            <br />
            <TrainingClients
              clients={training.Clients}
              training={training}
              addClientInPreviewOnly={true}
              onModalCancel={onCancel}
            />
          </>
        )}
        <TraininActionButtons>
          <Button
            startIcon={<PageviewIcon />}
            variant={'contained'}
            onClick={onTrainingView}
          >
            Podgląd
          </Button>
          {training.status !== 'finished' && (
            <>
              <Button
                startIcon={<EditIcon />}
                variant={'contained'}
                onClick={onTrainingEdit}
              >
                Edycja
              </Button>
              {!training.isClient && (
                <Button
                  startIcon={<ClearIcon />}
                  variant={'contained'}
                  onClick={() => onTrainingDelete(training.id)}
                >
                  Usuń
                </Button>
              )}
              {training.isClient && (
                <Button
                  startIcon={<BlockIcon />}
                  variant={'contained'}
                  onClick={() => handleOnTrainingCancel(training.id)}
                >
                  Anuluj
                </Button>
              )}
            </>
          )}
        </TraininActionButtons>
      </DialogContent>
      <DialogActionsStyled>
        <Button onClick={() => onCancel()} variant={'outlined'}>
          Zamknij
        </Button>
      </DialogActionsStyled>
    </>
  );
};
export default QuickTrainingViewModal;

const TraininActionButtons = styled('div')({
  padding: '15px 0',
  button: {
    margin: '0 15px 15px 0',
  },
});
