import { User } from './User';
import { EventClient } from './EventClient';

export type Client = {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  isUser?: boolean;
  User?: User;
  EventClient?: EventClient;
};
export const initialClient: Client = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};
