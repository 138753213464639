import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import http from '../../api/http';
import Former from '../../components/Former/Former';
import { useInfo } from '../../providers/info/info.context';
import { packageFields } from './PackageForm';
import { initialPackage, Package } from '../../types/Package';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePackageDelete } from '../../hooks/use-package-delete';

const PackageView = () => {
  const { setLoading, openModal } = useInfo();
  const [packageData, setPackageData] = useState<Package>(initialPackage);
  const params = useParams();
  const navigate = useNavigate();
  const onPackageDelete = usePackageDelete();

  useEffect(() => {
    if (params.packageId) {
      loadPackage(parseInt(params.packageId, 10));
    }
  }, []);

  const loadPackage = (packageId: number) => {
    setLoading(true);
    http()
      .get(`/packages/${packageId}`)
      .then(({ data }) => {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === 'boolean') {
            data[key] = data[key] === true ? 'tak' : 'nie';
          }
        });
        setPackageData(data);
      })
      .finally(() => setLoading(false));
  };

  const handleOnDelete = (packageId: number) => onPackageDelete(packageId);

  const handleOnEdit = (packageId: number) => {
    openModal({
      type: 'package_form',
      onChange: () => loadPackage(packageId),
      params: {
        packageId,
      },
    });
  };

  return (
    <>
      <PageHeader
        label={'Pakiet treningowy'}
        onBackButtonClick={() => navigate('/packages')}
        primaryButtonIcon={<EditIcon />}
        onPrimaryButtonClick={() => handleOnEdit(packageData.id)}
        secondaryButtonIcon={<DeleteIcon />}
        onSecondaryButtonClick={() => handleOnDelete(packageData.id)}
      />
      <PageContent>
        <Former
          fieldsConfig={packageFields}
          defaultValue={packageData}
          isReadOnly={true}
        />
      </PageContent>
    </>
  );
};
export default PackageView;
