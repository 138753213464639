import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Outlet } from 'react-router-dom';

const MessagesPage = () => {
  return (
    <Layout variant={'full'}>
      <Outlet />
    </Layout>
  );
};
export default MessagesPage;
