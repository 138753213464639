import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PageContent, SwitchFormControl } from './ProfilePage.styles';
import { Switch, FormLabel } from '@mui/material';

const ProfileSettings = () => {
  const navigate = useNavigate();
  const [trainerData, updateTrainerData] = useOutletContext<any>();

  const onChange = (data: object) => {
    updateTrainerData(data);
  };

  return (
    <>
      <PageHeader
        label={'Ustawienia'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        {trainerData.name && trainerData.slug && trainerData.cityId && (
          <SwitchFormControl>
            <Switch
              checked={!!trainerData.isActive}
              onChange={(ev) => onChange({ isActive: ev.target.checked })}
            />{' '}
            <FormLabel>profil widoczny</FormLabel>
          </SwitchFormControl>
        )}

        {/*<SwitchFormControl>*/}
        {/*  <Switch*/}
        {/*    checked={!!trainerData.isPersonal}*/}
        {/*    onChange={(ev) => onChange({ isPersonal: ev.target.checked })}*/}
        {/*  />{' '}*/}
        {/*  <FormLabel>trening osobisty</FormLabel>*/}
        {/*</SwitchFormControl>*/}

        {/*<SwitchFormControl>*/}
        {/*  <Switch*/}
        {/*    checked={!!trainerData.isHome}*/}
        {/*    onChange={(ev) =>*/}
        {/*      onChange({*/}
        {/*        isHome: ev.target.checked,*/}
        {/*      })*/}
        {/*    }*/}
        {/*  />{' '}*/}
        {/*  <FormLabel>trening domowy</FormLabel>*/}
        {/*</SwitchFormControl>*/}

        {/*<SwitchFormControl>*/}
        {/*  <Switch*/}
        {/*    checked={!!trainerData.isOnline}*/}
        {/*    onChange={(ev) =>*/}
        {/*      onChange({*/}
        {/*        isOnline: ev.target.checked,*/}
        {/*      })*/}
        {/*    }*/}
        {/*  />{' '}*/}
        {/*  <FormLabel>trening online</FormLabel>*/}
        {/*</SwitchFormControl>*/}

        {/*<SwitchFormControl sx={{ mb: 2 }}>*/}
        {/*  <Switch*/}
        {/*    checked={!!trainerData.isBooking}*/}
        {/*    onChange={(ev) =>*/}
        {/*      onChange({*/}
        {/*        isBooking: ev.target.checked,*/}
        {/*      })*/}
        {/*    }*/}
        {/*  />{' '}*/}
        {/*  <FormLabel>kalendarz automatyczny dla usług</FormLabel>*/}
        {/*</SwitchFormControl>*/}

        {/*<SwitchFormControl>*/}
        {/*  <Switch*/}
        {/*    checked={!!trainerData.isActive}*/}
        {/*    onChange={(ev) => onChange({ isActive: ev.target.checked })}*/}
        {/*  />{' '}*/}
        {/*  <FormLabel>Profil aktywny na TwojTrener.pl</FormLabel>*/}
        {/*</SwitchFormControl>*/}
      </PageContent>
    </>
  );
};
export default ProfileSettings;
