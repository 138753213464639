import React, { useEffect, useState } from 'react';
import http from '../../../api/http';
import { FieldType } from '../../../types/FieldType';
import Former from '../../../components/Former/Former';
import { useInfo } from '../../../providers/info/info.context';
import { initialTraining, Training } from '../../../types/Training';
import { Service } from '../../../types/Service';
import moment from 'moment';
import { EventLocation } from '../../../types/EventLocation';
import { apiLoadSports } from '../../../api/endpoints';
import {
  repeatingEventFields,
  trainingFields,
  trainingVisibilities,
} from './TrainingForm.config';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Color } from '../../../styles/colors';
import {
  initialRepeatingEventOptions,
  RepeatingEventOptions,
} from '../../../types/RepeatingEventOptions';
import RepeatingEventSlots from '../../../modals/TrainingCreateFormModal/RepeatingEventSlots';
import { getApiValidationErrors } from '../../../utils';

const TrainingForm = ({
  trainingId,
  notifyClient,
  submitFormTrigger = 0,
  initialStartDate,
  initialStartTime,
  initialDurationTime,
  onFormSubmit,
  onFormError,
  onCheckIsClient,
  isRepeatingEventEnabled = false,
  forceSave = false,
}: {
  submitFormTrigger: number;
  initialStartDate?: string;
  initialStartTime?: string;
  initialDurationTime?: number;
  onFormSubmit?: (data: Training) => void;
  onFormError?: () => void;
  trainingId?: number;
  notifyClient?: boolean;
  onCheckIsClient?: (isClient: boolean) => void;
  isRepeatingEventEnabled?: boolean;
  forceSave?: boolean;
}) => {
  const { setLoading, showMessage } = useInfo();
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const [trainingData, setTrainingData] = useState<Training>({
    ...initialTraining,
    startDate: initialStartDate || moment().format('YYYY-MM-DD'),
    startTime: initialStartTime || moment().add(1, 'hour').format('HH:00'),
    time: initialDurationTime || 30,
  });
  const [defaultEventLocationId, setDefaultEventLocationId] =
    useState<number>(0);
  const [repeatOptions, setRepeatOptions] = useState<RepeatingEventOptions>(
    initialRepeatingEventOptions
  );
  const [showSlots, setShowSlots] = useState(false);
  const [dateSlots, setDateSlots] = useState<object>({});

  useEffect(() => {
    loadFormElements();
  }, [trainingId]);

  useEffect(() => {
    if (showSlots && !isRepeatingEventEnabled) {
      setShowSlots(false);
    }
  }, [isRepeatingEventEnabled]);

  const loadFormElements = () => {
    setLoading(true);
    Promise.all([
      http().get('/services'),
      apiLoadSports(),
      http().get('/event-locations'),
    ])
      .then(([{ data: services }, sports, { data: eventLocations }]) => {
        updateFormFields(services, sports, eventLocations);
        if (trainingId) {
          return loadTraining(trainingId);
        } else {
          return Promise.resolve({
            ...initialTraining,
            startDate: initialStartDate || moment().format('YYYY-MM-DD'),
            startTime:
              initialStartTime || moment().add(1, 'hour').format('HH:00'),
            time: initialDurationTime || 30,
          });
        }
      })
      .then((training) => {
        setTrainingData(training);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateFormFields = (
    services = [],
    newSports = [],
    newEventLocations: EventLocation[] = [],
    overwrite: boolean = false
  ) => {
    setFormFields(
      trainingFields.map((field) => {
        if (field.key === 'serviceId') {
          setServices(services || []);
          field.items = [
            { id: -1, name: 'Wybierz usługę', disabled: true },
            { id: 0, name: 'Bez usługi' },
          ].concat(services || []);
        } else if (
          field.key === 'sportId' &&
          (newSports.length > 0 || overwrite)
        ) {
          field.items = [{ id: 0, name: 'Brak' }].concat(newSports || []);
        } else if (
          field.key === 'EventLocation' &&
          (newEventLocations.length > 0 || overwrite)
        ) {
          field.items = newEventLocations || [];
          // const defaultEventLocation: any =
          //   newEventLocations.find((evLoc: EventLocation) => evLoc.isDefault) ||
          //   newEventLocations[0];
          // if (defaultEventLocation && !trainingData?.eventLocationId) {
          //   const newTrainingData = {
          //     ...trainingData,
          //     eventLocationId:
          //       (defaultEventLocation && defaultEventLocation.id) ||
          //       (newEventLocations[0] && newEventLocations[0].id),
          //   };
          //   setDefaultEventLocationId(defaultEventLocation.id);
          //   setTrainingData(newTrainingData);
          // }
        } else if (field.key === 'visibility') {
          field.items = trainingVisibilities;
        }
        return field;
      })
    );
  };

  const loadTraining = (trainingId: number) => {
    setLoading(true);
    return http()
      .get(`/trainings/${trainingId}`)
      .then(({ data }) => {
        onCheckIsClient && onCheckIsClient(data.isClient);
        return {
          ...data,
          sportId: data.sportId || 0,
          serviceId: data.serviceId || 0,
          startDate: moment.unix(data.startTimestamp).format('YYYY-MM-DD'),
          startTime: moment.unix(data.startTimestamp).format('HH:mm'),
        };
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data: Training) => {
    let dataToSave: any = data;
    setLoading(true);
    setFormErrors({});

    if (isRepeatingEventEnabled) {
      dataToSave = {
        ...data,
        ...repeatOptions,
      };
    }

    (trainingId
      ? http().put(`/trainings/${trainingId}`, dataToSave, {
          params: { notifyClient },
        })
      : http().post(
          `/trainings${isRepeatingEventEnabled ? '/repeating' : ''}`,
          dataToSave,
          {
            params: {
              force: isRepeatingEventEnabled && forceSave ? true : undefined,
            },
          }
        )
    )
      .then(({ data }) => {
        if (!isRepeatingEventEnabled) {
          showMessage('Trening został zapisany');
          onFormSubmit && onFormSubmit(trainingData);
        } else if (!trainingId) {
          if (forceSave) {
            setDateSlots({});
            setShowSlots(false);
            showMessage('Treningi zostały zapisane');
            onFormSubmit && onFormSubmit(trainingData);
          } else {
            if (data && Object.keys(data).length > 0) {
              setDateSlots(data);
              setShowSlots(true);
            } else {
              setShowSlots(false);
              showMessage(
                'Dla podanej konfiguracji nie można dodać żadnego treningu - brak dostępnych terminów',
                'error'
              );
              onFormError && onFormError();
            }
          }
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === 'err' &&
          error.response.data.message
        ) {
          return showMessage(error.response.data.message, 'error');
        }
        setFormErrors(getApiValidationErrors(error));
        showMessage('Popraw błędy w formularzu', 'error');
        onFormError && onFormError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onServiceChanged = (data: any) => {
    if (
      data &&
      data.hasOwnProperty('serviceId') &&
      data.serviceId >= 0 &&
      data.serviceId !== trainingData.serviceId
    ) {
      // prefill training data with event type data
      const service: any = services.find((et) => et.id === data.serviceId);
      const newTrainingData: any = { ...data };
      newTrainingData.serviceId = data.serviceId;
      [
        'name',
        'time',
        'breakTime',
        'price',
        'sportId',
        'description',
        'EventLocation',
      ].forEach((key) => {
        let defaultValue = initialTraining[key as keyof Training];
        if (key === 'EventLocation') {
          defaultValue = defaultEventLocationId;
        }
        newTrainingData[key] = (service && service[key]) || defaultValue;
      });
      setTrainingData(newTrainingData);
    }
  };

  const onRepeatingEventChange = (data: any) => {
    setRepeatOptions(data);
  };

  return (
    <>
      <Box sx={{ display: showSlots ? 'block' : 'none' }}>
        <Alert color={'info'} className="mb10">
          Dla poniższych terminów utworzymy treningi. Sprawdź je, a następnie
          klinij "DODAJ"
        </Alert>
        <h3 className="mb10">{trainingData?.name}</h3>
        <RepeatingEventSlots dateSlots={dateSlots} />
      </Box>

      <Box id="EventCreateForm" sx={{ display: !showSlots ? 'block' : 'none' }}>
        <Former
          fieldsConfig={formFields}
          formErrors={formErrors}
          defaultValue={trainingData}
          onChange={onServiceChanged}
          onSubmit={onSubmit}
          submitTrigger={submitFormTrigger}
          hideButtons={true}
        />
        {!trainingId && (
          <>
            <br />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: Color.ttVioletLight,
                  borderRadius: '4px',
                  color: Color.white,
                  fontWeight: 600,
                  svg: { color: Color.white },
                }}
              >
                Konfiguruj serię treningów
              </AccordionSummary>
              <AccordionDetails>
                <Former
                  fieldsConfig={repeatingEventFields}
                  formErrors={formErrors}
                  defaultValue={repeatOptions}
                  onChange={onRepeatingEventChange}
                  hideButtons={true}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </>
  );
};
export default TrainingForm;
