import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useInfo } from '../../providers/info/info.context';
import { Client } from '../../types/Client';
import http from '../../api/http';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import AddIcon from '@mui/icons-material/Add';
import { Optional } from '../../styles/general';
import { useClientDelete } from '../../hooks/use-client-delete';

const ClientsList = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const { setLoading, openModal } = useInfo();
  const navigate = useNavigate();
  const onClientDelete = useClientDelete(() => loadClients());

  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = () => {
    setLoading(true);
    http()
      .get('/clients', { params: { query: '' } })
      .then(({ data }) => {
        setClients(data);
      })
      .finally(() => setLoading(false));
  };

  const onClientCreate = () => {
    openModal({
      type: 'client_form',
      onChange: () => loadClients(),
      params: {},
    });
  };

  const onClientUpdate = (clientId: number) => {
    openModal({
      type: 'client_form',
      onChange: () => loadClients(),
      params: {
        clientId,
      },
    });
  };

  const onClientRemove = (clientId: number) => onClientDelete(clientId);

  return (
    <>
      <PageHeader
        label={'Klienci'}
        onPrimaryButtonClick={() => onClientCreate()}
        primaryButtonIcon={<AddIcon />}
      />
      {clients.length === 0 && (
        <PageContent>Nie dodano jeszcze żadnego klienta.</PageContent>
      )}
      {clients.length > 0 && (
        <Table>
          <TableBody>
            {clients.map((client, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>
                    {client.firstName} {client.lastName}
                    {client.isUser && (
                      <>
                        <br />
                        <Optional>Użytkownik twojtrener.pl</Optional>
                      </>
                    )}
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => navigate(`/clients/view/${client.id}`)}
                    >
                      <PageviewIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => onClientUpdate(client.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => onClientRemove(client.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export default ClientsList;
