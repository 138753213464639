import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Link } from '@mui/material';

const ContentPage = () => {
  return (
    <Layout>
      <h2>Regulaminy</h2>
      <br />
      <div>
        <Link href={'https://twojtrener.pl/regulamin'} target={'_blank'}>
          Regulamin twojtrener.pl
        </Link>
        <br />
        <br />
        <Link
          href={'https://twojtrener.pl/polityka-prywatnosci'}
          target={'_blank'}
        >
          Polityka prywatności i cookies twojtrener.pl
        </Link>
      </div>
    </Layout>
  );
};
export default ContentPage;
