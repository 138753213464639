import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

const RepeatingEventSlots = ({ dateSlots }: { dateSlots: any }) => {
  useEffect(() => {
    const content = window.document.getElementById('EventFormModalContent');
    if (content) {
      content.scrollTop = 0;
    }
  }, [dateSlots]);

  return (
    <Grid container spacing={2}>
      {Object.keys(dateSlots).map((key: string) => {
        return (
          <Grid item md={6} key={key}>
            <h4>
              {dateSlots[key].displayDate} ({dateSlots[key].dayOfWeek})
            </h4>
            {Array.isArray(dateSlots[key].slots) && (
              <ul>
                {dateSlots[key].slots.map((slot: any) => {
                  return (
                    <li key={`${key}-${slot.startTime}-${slot.endTime}`}>
                      {slot.startTime} - {slot.endTime}
                    </li>
                  );
                })}
              </ul>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default RepeatingEventSlots;
