// import type { Dict } from '../types/types';
//
export const provinces = [
  { id: 1, name: 'dolnośląskie', slug: 'dolnoslaskie' },
  { id: 2, name: 'kujawsko-pomorskie', slug: 'kujawsko-pomorskie' },
  { id: 3, name: 'lubelskie', slug: 'lubelskie' },
  { id: 4, name: 'lubuskie', slug: 'lubuskie' },
  { id: 5, name: 'łódzkie', slug: 'lodzkie' },
  { id: 6, name: 'małopolskie', slug: 'malopolskie' },
  { id: 7, name: 'mazowieckie', slug: 'mazowieckie' },
  { id: 8, name: 'opolskie', slug: 'opolskie' },
  { id: 9, name: 'podkarpackie', slug: 'podkarpackie' },
  { id: 10, name: 'podlaskie', slug: 'podlaskie' },
  { id: 11, name: 'pomorskie', slug: 'pomorskie' },
  { id: 12, name: 'śląskie', slug: 'slaskie' },
  { id: 13, name: 'świętokrzyskie', slug: 'swietokrzyskie' },
  { id: 14, name: 'warmińsko-mazurskie', slug: 'warminsko-mazurskie' },
  { id: 15, name: 'wielkopolskie', slug: 'wielkopolskie' },
  { id: 16, name: 'zachodniopomorskie', slug: 'zachodniopomorskie' },
];

export const daysNames = [
  { id: 1, name: 'poniedziałek', value: '1' },
  { id: 2, name: 'wtorek', value: '2' },
  { id: 3, name: 'środa', value: '3' },
  { id: 4, name: 'czwartek', value: '4' },
  { id: 5, name: 'piątek', value: '5' },
  { id: 6, name: 'sobota', value: '6' },
  { id: 7, name: 'niedziela', value: '0' },
];
