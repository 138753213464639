import React, { useCallback, useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { useInfo } from '../../providers/info/info.context';
import http from '../../api/http';
import { Service } from '../../types/Service';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import AddIcon from '@mui/icons-material/Add';

const ServicesList = () => {
  const [services, setServices] = useState<Service[]>([]);
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = useCallback(() => {
    setLoading(true);
    http()
      .get('/services')
      .then(({ data }) => {
        setServices(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const onServiceRemove = (serviceId: number) => {
    if (window.confirm('Czy na pewno chcesz usunąć usługę?')) {
      setLoading(true);
      http()
        .delete(`/services/${serviceId}`)
        .then(() => {
          showMessage('Usługa została usunięta', 'success');
          loadServices();
        })
        .catch(() =>
          showMessage('Nie można usunąć usługi, spróbuj ponownie', 'error')
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <PageHeader
        label={'Usługi'}
        onPrimaryButtonClick={() => navigate('/services/add')}
        primaryButtonIcon={<AddIcon />}
      />
      {services.length === 0 && (
        <PageContent>Nie dodano jeszcze żadnej usługi.</PageContent>
      )}
      {services.length > 0 && (
        <Table>
          <TableBody>
            {services.map((et, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>
                    <strong>{et.name}</strong>
                    <br />
                    {(et.Sport && et.Sport.name) || ''}
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                    {et.price} zł
                    <br />
                    {et.time} min.{' '}
                    {`${et.breakTime > 0 ? `(+ ${et.breakTime} min.)` : ''}`}
                  </TableCell>
                  <TableCell width={24}>
                    {et.isPublic ? (
                      <VisibilityIcon color={'success'} />
                    ) : (
                      <VisibilityOffIcon color={'error'} />
                    )}
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => navigate(`/services/edit/${et.id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => onServiceRemove(et.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export default ServicesList;
