import http from '../api/http';
import { useInfo } from '../providers/info/info.context';
import { useNavigate } from 'react-router-dom';

export const usePackageDelete = (cb?: () => void) => {
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  const onPackageDelete = (packageId: number) => {
    if (window.confirm('Czy na pewno chcesz zarchiwizować pakiet?')) {
      setLoading(true);
      http()
        .delete(`/packages/${packageId}`)
        .then(() => {
          showMessage('Pakiet został zarchiwizowany', 'success');
          if (cb) {
            cb();
          } else {
            navigate('/packages');
          }
        })
        .catch(() =>
          showMessage(
            'Nie można zarchiwizować pakietu, spróbuj ponownie',
            'error'
          )
        )
        .finally(() => setLoading(false));
    }
  };

  return onPackageDelete;
};
