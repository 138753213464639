import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface ActionItem {
  id: string;
  label: string;
  onClick: () => void;
}

const ActionsButton = ({ actions = [] }: { actions: ActionItem[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color={'primary'} size={'small'} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        // id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
      >
        {actions.map((item: ActionItem) => {
          return (
            <MenuItem
              key={item.id}
              onClick={(ev) => {
                item.onClick();
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
export default ActionsButton;
