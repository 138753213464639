import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Client, initialClient } from '../../types/Client';
import http from '../../api/http';
import { FieldType } from '../../types/FieldType';
import Former from '../../components/Former/Former';
import { useInfo } from '../../providers/info/info.context';
import { Alert } from '@mui/material';
import { getApiValidationErrors } from "../../utils";

export const clientFields: FieldType[] = [
  { key: 'firstName', type: 'text', label: 'Imię', required: true },
  { key: 'lastName', type: 'text', label: 'Nazwisko', required: true },
  { key: 'email', type: 'email', label: 'Adres e-mail' },
  { key: 'phone', type: 'text', label: 'Nr telefonu' },
  {
    key: 'comment',
    type: 'textarea',
    label: 'Notatka',
    labelDescription: 'Widoczne tylko dla trenera',
  },
];

const ClientForm = ({
  clientId,
  submitFormTrigger = 0,
  onFormSubmit,
}: {
  clientId?: number;
  submitFormTrigger: number;
  onFormSubmit?: (data: Client) => void;
}) => {
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const { setLoading, showMessage } = useInfo();
  const [pageTitle, setPageTitle] = useState<string>('Dodaj klienta');
  const [clientData, setClientData] = useState<Client>(initialClient);
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId) {
      setPageTitle('Edycja klienta');
      loadClient(clientId);
    } else {
      setFormFields(clientFields);
    }
  }, [clientId]);

  const loadClient = (clientId: number) => {
    setLoading(true);
    http()
      .get(`/clients/${clientId}`)
      .then(({ data }) => {
        setClientData(data);
        if (data.isUser) {
          setFormFields(
            clientFields.map((field) => ({
              ...field,
              isReadonly: field.key !== 'comment',
            }))
          );
        } else {
          setFormFields(clientFields);
        }
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data: Client) => {
    setLoading(true);
    setFormErrors({});
    delete data.isUser;

    (clientId
      ? http().put(`/clients/${clientId}`, data)
      : http().post('/clients', data)
    )
      .then(({ data: createdClient }) => {
        showMessage('Klient został zapisany');
        onFormSubmit && onFormSubmit({ ...data, ...createdClient?.content });
      })
      .catch((error) => {
        setFormErrors(getApiValidationErrors(error));
        showMessage('Popraw błędy w formularzu', 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {clientData.isUser && (
        <Alert severity={'info'}>
          Dane klienta są pobierane z jego konta użytkownika w serwisie
          twojtrener.pl i nie można ich edytować.
        </Alert>
      )}
      <Former
        fieldsConfig={formFields}
        formErrors={formErrors}
        defaultValue={clientData}
        onSubmit={onSubmit}
        submitTrigger={submitFormTrigger}
        hideButtons={true}
      />
    </>
  );
};
export default ClientForm;
