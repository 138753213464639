import React, { useEffect, useState } from 'react';
import FilesUpload from '../FilesUpload/FilesUpload';
import { useInfo } from '../../providers/info/info.context';
import http from '../../api/http';
import { marketplaceUrl } from '../../config/config';
import { Avatar, Box, Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Image, initialImage } from '../../types/Image';

const EditProfilePhoto = ({ trainerSlug }: { trainerSlug: string }) => {
  const { setLoading, showMessage } = useInfo();
  const [profileImage, setProfileImage] = useState<Image>(initialImage);

  useEffect(() => {
    loadProfilePhoto(trainerSlug);
  }, []);

  const loadProfilePhoto = (trainerSlug: string) => {
    setLoading(true);
    http()
      .get(`/files/${trainerSlug}/profile-photo`)
      .then(({ data }) => {
        if (data && data.id) {
          setProfileImage(data);
        }
      })
      .finally(() => setLoading(false));
  };

  const removeProfilePhoto = () => {
    if (profileImage.id) {
      setLoading(true);
      http()
        .delete(`/files/${profileImage.id}`)
        .then(({ data }) => {
          setProfileImage(initialImage);
          showMessage('Zdjęcie profilowe zostało usunięte', 'success');
        })
        .catch(() => {
          showMessage(
            'Nie można usunąć zdjęcia profilowego, spróbuj ponownie',
            'error'
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const onFileUploaded = (fileData: Image) => {
    if (fileData && fileData.id) {
      setProfileImage(fileData);
    }
  };

  return (
    <>
      {profileImage.filename && (
        <Box sx={{ textAlign: 'center', width: 200 }}>
          <Avatar
            src={`${marketplaceUrl}/content/trainer_${profileImage.filename}`}
            sx={{ width: 200, height: 200, margin: '0 auto 20px' }}
          />
          <Button
            variant={'outlined'}
            startIcon={<DeleteForeverIcon />}
            size={'small'}
            color={'error'}
            onClick={() => removeProfilePhoto()}
          >
            Usuń zdjęcie
          </Button>
        </Box>
      )}
      {!profileImage.filename && (
        <>
          <p>
            Zdjęcie zostanie wykadrowane do rozmiaru 500 x 500px.
            <br />
            Dozwolony typ pliku to JPG lub PNG, maksymalny rozmiar 5MB.
          </p>
          <br />
          <FilesUpload
            trainerSlug={trainerSlug}
            onFileUploaded={onFileUploaded}
          />
        </>
      )}
    </>
  );
};
export default EditProfilePhoto;
