import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import { useInfo } from '../../providers/info/info.context';
import http from '../../api/http';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import { MessagesListItem } from '../../types/Message';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Color } from '../../styles/colors';
import styled from '@emotion/styled';

const MessagesList = () => {
  const [messages, setMessages] = useState<MessagesListItem[]>([]);
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = () => {
    setLoading(true);
    http()
      .get('/messages', { params: {} })
      .then(({ data }) => {
        setMessages(data.rows);
      })
      .catch(() => showMessage('Nie można załadować listy wiadomości', 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageHeader
        label={'Wiadomości'}
        secondaryButtonIcon={<RefreshIcon />}
        onSecondaryButtonClick={() => loadMessages()}
      />
      {messages.length === 0 && <PageContent>Brak wiadomości.</PageContent>}
      {messages.length > 0 && (
        <Table>
          <TableBody>
            {messages.map((msg, k) => {
              return (
                <TableRow key={k}>
                  <TableCell
                    onClick={() => navigate(`/messages/view/${msg.uid}`)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <strong>{msg.name}</strong>
                    <br />
                    <MessageContent>{msg.content}</MessageContent>
                    <MessageCreated>{msg.created}</MessageCreated>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton
                      size={'small'}
                      onClick={() => navigate(`/messages/view/${msg.uid}`)}
                    >
                      <PageviewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </>
  );
};
export default MessagesList;

const MessageContent = styled(Box)({
  color: Color.grey,
  height: '26px',
  overflow: 'hidden',
});

const MessageCreated = styled(Box)({
  color: Color.black,
  fontSize: '12px',
});
