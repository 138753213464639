import { FieldType } from '../../types/FieldType';

export const clientSelectFields: FieldType[] = [
  {
    key: 'client',
    type: 'autocomplete',
    label: 'Wybierz klienta',
    required: true,
  },
  {
    key: 'notifyClient',
    type: 'boolean',
    label: 'Wyślij powiadomienie e-mail',
    labelDescription:
      'Jeżeli klient posiada adres e-mail przypisany do swojego konta',
    required: true,
  },
];
