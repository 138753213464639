import React from 'react';
import src from '../../assets/icons/ico_facebook.svg';

const FacebookIcon = () => {
  return (
    <>
      <img src={src} alt="Facebook logo" width={22} height={22} />
    </>
  );
};
export default FacebookIcon;
