import React from 'react';
import { ModalType } from './modals.types';
import QuestionModal from './QuestionModal/QuestionModal';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TrainingCreateFormModal from './TrainingCreateFormModal/TrainingCreateFormModal';
import QuickTrainingViewModal from './QuickTrainingViewModal/QuickTrainingViewModal';
import ClientFormModal from './ClientFormModal/ClientFormModal';
import PackageFormModal from './PackageFormModal/PackageFormModal';
import MessageModal from './MessageModal/MessageModal';
import { BtnClose } from './modal.styles';

const ModalContainer = ({
  isModalVisible,
  type,
  params,
  onCancel,
  onChange,
}: {
  isModalVisible: boolean;
  type: ModalType;
  params?: any;
  onCancel?: (data?: any) => void;
  onChange?: (data?: any) => void;
}) => {
  const onModalCancel = (data?: any) => {
    onCancel && onCancel(data);
  };

  const onModalChange = (data?: any) => {
    onChange && onChange(data);
  };

  return (
    <Dialog
      open={isModalVisible}
      PaperProps={{ sx: { position: 'fixed', top: 10 } }}
    >
      <BtnClose onClick={() => onModalCancel()}>
        <CloseIcon fontSize={'large'} />
      </BtnClose>
      {type === 'question' && (
        <QuestionModal
          header={params.header}
          content={params.content}
          cancelLabel={params.cancelLabel}
          confirmLabel={params.confirmLabel}
          onCancel={onModalCancel}
          onChange={onModalChange}
        />
      )}
      {type === 'package_form' && (
        <PackageFormModal
          onCancel={onModalCancel}
          onChange={onModalChange}
          params={params}
        />
      )}
      {type === 'client_form' && (
        <ClientFormModal
          onCancel={onModalCancel}
          onChange={onModalChange}
          params={params}
        />
      )}
      {type === 'training_create' && (
        <TrainingCreateFormModal
          onCancel={onModalCancel}
          onChange={onModalChange}
          params={params}
        />
      )}
      {type === 'quick_training_view' && (
        <QuickTrainingViewModal training={params} onCancel={onModalCancel} />
      )}
      {type === 'message_modal' && (
        <MessageModal
          messageId={params.messageId}
          userName={params.userName}
          onCancel={onModalCancel}
        />
      )}
    </Dialog>
  );
};
export default ModalContainer;
