import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, ListItemAvatar } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Color } from '../../styles/colors';
import { useNavigate } from 'react-router-dom';

const SubNavigation = React.memo(({ items }: { items: any[] }) => {
  const navigate = useNavigate();
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: '500px',
      }}
      component="nav"
    >
      {items.length > 0 &&
        items.map((item, k) => {
          return (
            <div key={k}>
              <ListItem button onClick={() => navigate(item.path)}>
                {item.icon && (
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: Color.ttViolet }}>
                      {item.icon}
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText primary={item.label} />
                <KeyboardArrowRightIcon sx={{ color: Color.ttVioletDark }} />
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </List>
  );
});
export default SubNavigation;
