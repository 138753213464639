import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const NavItem = ({
  label,
  path,
  icon,
  onClick,
}: {
  label: string;
  path: string;
  icon: JSX.Element;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={location.pathname.indexOf(path) >= 0 && !onClick}
        onClick={() => (onClick && onClick()) || navigate(path)}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};
export default NavItem;
