import http from '../api/http';
import { useInfo } from '../providers/info/info.context';
import { useNavigate } from 'react-router-dom';

export const useClientDelete = (cb?: () => void) => {
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  const onClientDelete = (clientId: number) => {
    if (
      window.confirm(
        'Czy na pewno chcesz usunąć klienta? W zakończonych treningach będzie oznaczony jako "usunięty".'
      )
    ) {
      setLoading(true);
      http()
        .delete(`/clients/${clientId}`)
        .then(() => {
          showMessage('Klient został usunięty', 'success');
          if (cb) {
            cb();
          } else {
            navigate('/clients');
          }
        })
        .catch(() =>
          showMessage('Nie można usunąć klienta, spróbuj ponownie', 'error')
        )
        .finally(() => setLoading(false));
    }
  };

  return onClientDelete;
};
