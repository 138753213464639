import React from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Link } from '@mui/material';
import { marketplaceUrl } from '../../config/config';

const AuthLayout = ({
  type = 'login',
  children,
}: {
  type?: 'login' | 'register';
  children: React.ReactNode;
}) => {
  let backgroundImage = 'url(/img/img_bg_login.jpg)';
  if (type === 'register') {
    backgroundImage = 'url(/img/img_bg_register.jpg)';
  }
  return (
    <AuthLayoutContainer>
      <Grid container className="authLayoutContainer">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={'bg-image'}
          sx={{
            backgroundImage,
            height: {
              xs: '0px !important',
              sm: '250px !important',
              md: '100% !important',
            },
            backgroundSize: {
              xs: '600px auto !important',
              sm: '900px auto !important',
              md: 'auto 100% !important',
            },
            backgroundPosition:
              type === 'register'
                ? {
                    xs: 'center -130px !important',
                    sm: 'center -200px !important',
                    md: 'top center !important',
                  }
                : {
                    xs: 'center -190px !important',
                    sm: 'center -300px !important',
                    md: 'top center !important',
                  },
            marginBottom: {
              xs: '30px',
            },
          }}
        />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ paddingTop: { md: 10 } }}
          className={'login-form'}
        >
          <Grid container spacing={2} className="authLayoutContainer">
            <Grid item md={2} xs={1} />
            <Grid item md={8} xs={10}>
              <div className={'logo'}>
                <Link href={marketplaceUrl}>
                  <img
                    src={'/img/img_logo_twojtrener.png'}
                    height={40}
                    alt={'twojtrener.pl'}
                  />
                </Link>
              </div>
              <Box>{children}</Box>
            </Grid>
            <Grid item md={2} xs={1} />
          </Grid>
        </Grid>
      </Grid>
    </AuthLayoutContainer>
  );
};
export default AuthLayout;

const AuthLayoutContainer = styled(Box)({
  height: '100%',
  h2: {
    textAlign: 'center',
    marginBottom: 10,
  },
  '.logo': {
    textAlign: 'center',
    marginBottom: '30px',
  },
  '.authLayoutContainer': {
    height: '100%',
  },
  '.login-form': {
    height: '100%', // TODO: change
  },
  '.bg-image': {
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'auto 100%',
  },
});
