import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { MessagesListItem } from '../../types/Message';
import { Box, Button, Chip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import styled from '@emotion/styled';
import RefreshIcon from '@mui/icons-material/Refresh';

const MessageView = () => {
  const { setLoading, openModal } = useInfo();
  const [messages, setMessages] = useState<MessagesListItem[]>([]);
  const [userName, setUserName] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.messageId) {
      loadMessages(params.messageId);
    }
  }, [params.messageId]);

  const loadMessages = (messageId: string) => {
    setLoading(true);
    http()
      .get(`/messages/${messageId}`)
      .then(({ data }) => {
        setMessages(data);
        setUserName(data[0].name);
      })
      .finally(() => setLoading(false));
  };

  const onMessageReply = () => {
    openModal({
      type: 'message_modal',
      params: {
        messageId: messages[0].uid,
        userName: messages[0].name,
      },
      onCancel: (reloadMessages: boolean) =>
        params.messageId && reloadMessages
          ? loadMessages(params.messageId)
          : false,
    });
  };

  return (
    <>
      <PageHeader
        label={userName}
        onBackButtonClick={() => navigate('/messages')}
        secondaryButtonIcon={<RefreshIcon />}
        onSecondaryButtonClick={() =>
          params.messageId && loadMessages(params.messageId)
        }
      />
      <PageContent>
        <Button
          variant={'contained'}
          startIcon={<ReplyIcon />}
          className="mb20"
          onClick={() => onMessageReply()}
        >
          Odpowiedz
        </Button>
        {messages.length > 0 && (
          <Box>
            {messages.map((msg) => {
              return (
                <Box
                  key={msg.uid}
                  sx={{
                    marginBottom: '20px',
                    width: '100%',
                    textAlign: msg.isAuthorTrainer ? 'right' : 'left',
                  }}
                >
                  <MessageCreated>{msg.created}</MessageCreated>
                  <br />
                  <Chip
                    label={msg.content}
                    color={msg.isAuthorTrainer ? 'info' : 'default'}
                    sx={{
                      fontSize: '16px',
                      maxWidth: {
                        md: '80%',
                      },
                      textAlign: 'left',
                      height: 'auto',
                      padding: '10px 5px',
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal',
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </PageContent>
    </>
  );
};
export default MessageView;

const MessageCreated = styled('span')({
  fontSize: '12px',
});
