import moment from 'moment';
import { FormError } from './types/FormError';

export const flatData = (data: any) => JSON.parse(JSON.stringify(data));

export const displayTime = (timestamp?: number) => {
  if (!timestamp) {
    return '';
  }
  return moment.unix(timestamp).format('HH:mm');
};

export const getApiValidationErrors = (errorsResponse: any) => {
  const errObject: any = {};
  if (
    errorsResponse &&
    errorsResponse.response &&
    errorsResponse.response.data &&
    Array.isArray(errorsResponse.response.data.errors)
  ) {
    const errorsList = errorsResponse.response.data.errors;
    for (let i = 0; i < errorsList.length; i++) {
      if (!errObject[errorsList[i].param]) {
        errObject[errorsList[i].param] = errorsList[i].msg;
      }
    }
  }
  return errObject;
};

export const apiErrorsAsFormErrors = (errorsResponse: any): FormError[] => {
  if (
    errorsResponse?.response?.data?.errors &&
    Array.isArray(errorsResponse.response.data.errors)
  ) {
    return errorsResponse.response.data.errors.map((errObj: any) => ({
      key: errObj.param,
      message: errObj.msg,
    }));
  }
  return [];
};
