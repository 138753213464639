import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { PageContent } from '../../ProfilePage/ProfilePage.styles';
import http from '../../../api/http';
import { useInfo } from '../../../providers/info/info.context';
import { initialTraining, Training } from '../../../types/Training';
import FormerFieldPreview from '../../../components/Former/FormerFieldPreview';
import {
  getTrainingVisibility,
  normalizeTrainingItem,
} from '../trainings.helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import TrainingClients from '../../../components/TrainingClients/TrainingClients';
import { useTrainingDelete } from '../../../hooks/use-training-delete.hook';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import { useTrainingCancel } from '../../../hooks/use-training-cancel.hook';
import { Client } from '../../../types/Client';
import { Chip } from '@mui/material';

const TrainingView = () => {
  const { setLoading, openModal } = useInfo();
  const onTrainingDelete = useTrainingDelete();
  const onTrainingCancel = useTrainingCancel();
  const [trainingData, setTrainingData] = useState<Training>({
    ...initialTraining,
  });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.trainingId) {
      loadTraining(parseInt(params.trainingId, 10));
    }
  }, [params.trainingId]);

  const loadTraining = (trainingId: number) => {
    setLoading(true);
    http()
      .get(`/trainings/${trainingId}`)
      .then(({ data }) => {
        setTrainingData(normalizeTrainingItem(data));
      })
      .finally(() => setLoading(false));
  };

  const handleOnDelete = (trainingId: number) => onTrainingDelete(trainingId);

  const handleOnCancel = (trainingId: number) =>
    onTrainingCancel(trainingId, () => {
      loadTraining(trainingId);
    });

  const handleOnEdit = (trainingId: number) => {
    openModal({
      type: 'training_create',
      onChange: () => loadTraining(trainingId),
      params: {
        trainingId,
      },
    });
  };

  const onClientsChange = (clients: Client[]) => {
    const newTrainingData = { ...trainingData };
    newTrainingData.Clients = clients;
    newTrainingData.isClient = clients.length > 0;
    setTrainingData(newTrainingData);
  };

  return (
    <>
      <PageHeader
        label="Podgląd treningu"
        onBackButtonClick={() => navigate(`/workouts`)}
        primaryButtonIcon={
          trainingData.status !== 'finished' ? <EditIcon /> : undefined
        }
        onPrimaryButtonClick={() =>
          trainingData.status !== 'finished'
            ? handleOnEdit(trainingData.id)
            : undefined
        }
        secondaryButtonIcon={
          !trainingData.isClient && trainingData.status !== 'finished' ? (
            <DeleteIcon />
          ) : undefined
        }
        onSecondaryButtonClick={
          !trainingData.isClient && trainingData.status !== 'finished'
            ? () => handleOnDelete(trainingData.id)
            : undefined
        }
        thirdButtonIcon={
          trainingData.isClient && trainingData.status !== 'finished' ? (
            <BlockIcon />
          ) : undefined
        }
        onThirdButtonClick={
          trainingData.isClient && trainingData.status !== 'finished'
            ? () => handleOnCancel(trainingData.id)
            : undefined
        }
      />
      <PageContent>
        <>
          {trainingData.status === 'finished' && (
            <Chip
              sx={{ mb: 1 }}
              label={'Trening Zakończony'}
              color={'error'}
              size={'small'}
            />
          )}
          <FormerFieldPreview label={'Nazwa'} value={trainingData.name} />
          <FormerFieldPreview
            label={'Rozpoczęcie'}
            value={`${trainingData.startDate} ${trainingData.startTime}`}
          />
          <FormerFieldPreview
            label={'Czas trwania'}
            value={`${trainingData.time} min.`}
          />
          <FormerFieldPreview
            label={'Koszt'}
            value={`${trainingData.price} zł`}
          />
          <FormerFieldPreview
            label={'Lokalizacja'}
            value={`${
              (trainingData.EventLocation && trainingData.EventLocation.name) ||
              '- - -'
            }`}
          />
          <FormerFieldPreview
            label={'Przerwa po treningu'}
            value={`${trainingData.breakTime} min.`}
          />
          <FormerFieldPreview
            label={'Widoczność'}
            value={`${
              getTrainingVisibility(trainingData.visibility).name || '- - -'
            }`}
          />
          {/*<FormerFieldPreview*/}
          {/*  label={'Dyscyplina'}*/}
          {/*  value={`${*/}
          {/*    (trainingData.Sport && trainingData.Sport.name) || '- - -'*/}
          {/*  }`}*/}
          {/*/>*/}
          <FormerFieldPreview
            label={'Opis'}
            value={`${trainingData.description || '- - -'}`}
          />
          {Array.isArray(trainingData.Clients) && (
            <TrainingClients
              clients={trainingData.Clients}
              training={trainingData}
              onChange={onClientsChange}
            />
          )}
        </>
      </PageContent>
    </>
  );
};
export default TrainingView;
