import { EventLocation } from './EventLocation';

export interface Service {
  id: number;
  name: string;
  isPublic: boolean;
  price: number;
  sportId: number;
  time: number;
  breakTime: number;
  Sport?: any;
  EventLocation?: EventLocation | null;
}

export const initialService: Service = {
  id: 0,
  name: '',
  isPublic: true,
  price: 0,
  sportId: 0,
  time: 30,
  breakTime: 0,
  EventLocation: null,
};
