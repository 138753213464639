import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Training } from '../../types/Training';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { normalizeTrainingItem } from './trainings.helpers';
import moment from 'moment';
import 'moment/locale/pl';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import styled from '@emotion/styled';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Color } from '../../styles/colors';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { isMobile } from 'react-device-detect';

const localizer = momentLocalizer(moment);

const WorkoutsList = () => {
  const [trainings, setTrainings] = useState<Training[]>([]);
  const { openModal, setLoading } = useInfo();
  const navigate = useNavigate();
  const [dateStart, setDateStart] = useState<string>();
  const [dateEnd, setDateEnd] = useState<string>();
  const [scrollToTime, setScrollToTime] = useState<Date>(
    new Date(new Date().getTime())
  );
  const [defaultView, setDefaultView] = useState<View>('month');

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    if (isMobile) {
      setDefaultView('day');
      loadTrainings(
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      );
    } else {
      setDefaultView('week');
      const weekStart = moment().startOf('week').format('YYYY-MM-DD');
      const weekEnd = moment().endOf('week').format('YYYY-MM-DD');
      setDateStart(weekStart);
      setDateEnd(weekEnd);
      loadTrainings(weekStart, weekEnd);
    }
  }, []);

  const loadTrainings = (localDateStart?: string, localDateEnd?: string) => {
    setLoading(true);
    http()
      .get('/trainings', {
        params: {
          dateStart: localDateStart || dateStart,
          dateEnd: localDateEnd || dateEnd,
        },
      })
      .then(({ data: trainingsData }) => {
        if (Array.isArray(trainingsData)) {
          setTrainings(
            trainingsData.map((item: any) => normalizeTrainingItem(item))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleViewTraining = (training: Training) => {
    openModal({
      type: 'quick_training_view',
      params: training,
      onCancel: (result) => (result ? loadTrainings() : false),
    });
  };
  const handleCreateTraining = (start?: Date, end?: Date) => {
    const startDate = start
      ? moment(start).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    const startTime = start
      ? moment(start).format('HH:mm')
      : moment().add(1, 'hour').format('HH:00');
    const durationTime =
      start && end ? moment(end).diff(moment(start)) / 1000 / 60 : 30;
    openModal({
      type: 'training_create',
      onChange: () => loadTrainings(),
      params: {
        startDate,
        startTime,
        durationTime,
      },
    });
  };

  const onSelectSlot = (slot: any) => {
    handleCreateTraining(slot.start, slot.end);
  };

  const onRangeChange = (range: any) => {
    let start = moment().format('YYYY-MM-DD');
    let end = moment().format('YYYY-MM-DD');
    if (Array.isArray(range)) {
      start = range[0];
      end = range[range.length - 1];
    } else if (range.start && range.end) {
      start = range.start;
      end = range.end;
    }
    setDateStart(moment(start).format('YYYY-MM-DD'));
    setDateEnd(moment(end).format('YYYY-MM-DD'));
    loadTrainings(
      moment(start).format('YYYY-MM-DD'),
      moment(end).format('YYYY-MM-DD')
    );
  };

  return (
    <>
      <PageHeader
        label={'Kalendarz treningów'}
        primaryButtonIcon={<AddIcon />}
        onPrimaryButtonClick={() => handleCreateTraining()}
        secondaryButtonIcon={<RefreshIcon />}
        onSecondaryButtonClick={() => loadTrainings()}
      />
      <>
        <CalendarContainer id={'CalendarContainer'}>
          <Calendar
            localizer={localizer}
            formats={{
              dateFormat: 'dd',
              timeGutterFormat: 'HH:mm',
              agendaTimeFormat: 'HH:mm',
            }}
            view={defaultView}
            onView={(view) => setDefaultView(view)}
            onNavigate={(date, view) => {
              // console.log(date, view);
            }}
            events={trainings}
            eventPropGetter={(event, start, end, isSelected) => {
              return {
                style: {
                  backgroundColor: event.isClient
                    ? Color.eventWithClient
                    : event.status === 'finished'
                    ? Color.finishedEventWithoutClient
                    : undefined,
                  borderColor: event.isClient
                    ? Color.eventWithClientDark
                    : event.status === 'finished'
                    ? Color.finishedEventWithoutClientDark
                    : undefined,
                },
              };
            }}
            onSelectEvent={handleViewTraining}
            onSelectSlot={onSelectSlot}
            onRangeChange={onRangeChange}
            selectable={true}
            step={15}
            scrollToTime={scrollToTime}
            messages={{
              date: 'Data',
              time: 'Czas',
              event: 'Wizyta',
              allDay: 'Cały dzień',
              week: 'Tydzień',
              work_week: 'Tydzień pracy',
              day: 'Dzień',
              month: 'Miesiąc',
              previous: 'Poprzedni',
              next: 'Następny',
              yesterday: 'Wczoraj',
              tomorrow: 'Jutro',
              today: 'Dzisiaj',
              agenda: 'Agenda',
              noEventsInRange: 'Brak treningów',
              showMore: (total) => `+${total} więcej`,
            }}
          />
        </CalendarContainer>
      </>
    </>
  );
};
export default WorkoutsList;

const CalendarContainer = styled('div')({
  padding: 20,
  // height: 700,
  // fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,

  '.rbc-label': {
    fontSize: 14,
  },

  '.rbc-event-content': {
    fontSize: 14,
  },

  '.rbc-header': {
    fontSize: 14,
    fontWeight: 700,
  },

  '.rbc-toolbar .rbc-toolbar-label': {
    fontWeight: 600,
    fontSize: 16,
  },

  '.rbc-current-time-indicator': {
    backgroundColor: '#ff0000',
    height: 2,
  },
});

const onWindowResize = () => {
  const cal: HTMLElement | null = document.getElementById('CalendarContainer');
  if (cal) {
    cal.style.height = document.body.offsetHeight - cal.offsetTop + 'px';
  }
};
