import { trainingVisibilities } from './TrainingForm/TrainingForm.config';
import { Training } from '../../types/Training';
import moment from 'moment/moment';

export const getTrainingVisibility = (id: number) => {
  return (
    trainingVisibilities.find((item) => item.id === id) || {
      id: 0,
      name: '',
      shortName: '',
    }
  );
};

export const normalizeTrainingItem = (item: any): Training => {
  const isGroupEvent = item.maxParticipantsAmount > 1;
  return {
    ...item,
    startDate: moment.unix(item.startTimestamp).format('DD.MM.YYYY'),
    startTime: moment.unix(item.startTimestamp).format('HH:mm'),
    endTime: moment.unix(item.endTimestamp).format('HH:mm'),
    start: new Date(item.startTimestamp * 1000),
    end: new Date(item.endTimestamp * 1000),
    title: `${item.visibility === 0 ? `[priv] ${item.name}` : item.name} ${
      isGroupEvent
        ? `(${item.participantsAmount} / ${item.maxParticipantsAmount})`
        : ''
    }`,
  };
};
