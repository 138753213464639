import React from 'react';
import Layout from '../../components/Layout/Layout';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import PagesIcon from '@mui/icons-material/Pages';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <h2>Witaj w twojtrener.pl!</h2>
      <Box sx={{ mb: 3 }}>
        <span className="fs20">👋</span> Cieszymy się, że do nas dołączyłeś!{' '}
        <span className="fs20">💪</span>
      </Box>
      <Box>
        <Button
          variant={'contained'}
          size={'large'}
          onClick={() => {
            navigate('/profile');
          }}
          startIcon={<AccountBoxIcon />}
        >
          Edycja wizytówki
        </Button>

        <br />
        <br />

        <Button
          variant={'contained'}
          size={'large'}
          onClick={() => {
            navigate('/workouts');
          }}
          startIcon={<FitnessCenterIcon />}
        >
          Treningi
        </Button>

        <br />
        <br />
        <Button
          variant={'contained'}
          size={'large'}
          onClick={() => {
            navigate('/clients');
          }}
          startIcon={<PeopleIcon />}
        >
          Klienci
        </Button>

        <br />
        <br />

        <Button
          variant={'contained'}
          size={'large'}
          onClick={() => {
            navigate('/packages');
          }}
          startIcon={<PagesIcon />}
        >
          Pakiety
        </Button>
      </Box>
    </Layout>
  );
};
export default HomePage;
