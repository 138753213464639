import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Header from './Header';
import Navigation from './Navigation';
import Toolbar from '@mui/material/Toolbar';
import { Main } from './Layout.styles';

const Layout = ({
  variant,
  children,
}: {
  variant?: string;
  children: React.ReactNode;
}) => {
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const sx = variant === 'full' ? { padding: '0 !important' } : {};

  const handleNavigationToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header handleNavigationToggle={handleNavigationToggle} />
      <Navigation
        handleNavigationToggle={handleNavigationToggle}
        isOpen={isMobileOpen}
      />
      <Main sx={sx}>
        <Toolbar />
        {children}
      </Main>
    </Box>
  );
};
export default Layout;
