import React from 'react';
import { FormControl, FormLabel } from '@mui/material';

const FormerFieldPreview = ({
  label,
  value,
}: {
  label: string;
  value?: string;
}) => {
  return (
    <FormControl sx={{ mb: 2, width: '100%' }}>
      <FormLabel>{label}</FormLabel>
      <strong>{value || '- - -'}</strong>
    </FormControl>
  );
};
export default FormerFieldPreview;
