import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './HomePage/HomePage';
import ProtectedRoute from '../providers/auth/ProtectedRoute';
import WelcomePage from './WelcomePage/WelcomePage';
import ProfilePage from './ProfilePage/ProfilePage';
import ConfirmEmailPage from './RegisterPage/ConfirmEmailPage';
import RegisterPage from './RegisterPage/RegisterPage';
import LoginPage from './LoginPage/LoginPage';
import ErrorPage from './ErrorPage/ErrorPage';
import ClientsPage from './ClientsPage/ClientsPage';
import WorkoutsPage from './WorkoutsPage/WorkoutsPage';
import ContentPage from './ContentPage/ContentPage';
import ProfileContactData from './ProfilePage/ProfileContactData';
import ProfilePageNavigation from './ProfilePage/ProfilePageNavigation';
import ProfileGeneralData from './ProfilePage/ProfileGeneralData';
import ProfilePhoto from './ProfilePage/ProfilePhoto';
import ProfileGallery from './ProfilePage/ProfileGallery';
import ProfileStatistics from './ProfilePage/ProfileStatistics';
import ProfileSettings from './ProfilePage/ProfileSettings';
import ProfilePreview from './ProfilePage/ProfilePreview';
import ContactPage from './ContactPage/ContactPage';
import ServicesList from './ServicesPage/ServicesList';
import ServiceForm from './ServicesPage/ServiceForm';
import WorkoutsList from './WorkoutsPage/WorkoutsList';
import ClientsList from './ClientsPage/ClientsList';
import ClientView from './ClientsPage/ClientView';
import EventLocationsList from './EventLocation/EventLocationsList';
import EventLocationForm from './EventLocation/EventLocationForm';
import TrainingView from './WorkoutsPage/TrainingForm/TrainingView';
import PackagesPage from './PackagesPage/PackagesPage';
import PackagesList from './PackagesPage/PackagesList';
import PackageView from './PackagesPage/PackageView';
import ProfilePlaces from './ProfilePage/ProfilePlaces';
import MessagesPage from './MessagesPage/MessagesPage';
import MessagesList from './MessagesPage/MessagesList';
import MessageView from './MessagesPage/MessageView';
import ServicesPage from './ServicesPage/ServicesPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import FacebookLoginPage from './LoginPage/FacebookLoginPage';
import AvailabilitiesList from './AvailabilityPage/AvailabilitiesList';
import AvailabilityPage from './AvailabilityPage/AvailabilityPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="home"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="packages"
        element={
          <ProtectedRoute>
            <PackagesPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<PackagesList />} />
        <Route path={'view/:packageId'} element={<PackageView />} />
      </Route>
      <Route
        path="clients"
        element={
          <ProtectedRoute>
            <ClientsPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<ClientsList />} />
        <Route path={'view/:clientId'} element={<ClientView />} />
      </Route>
      <Route
        path="messages"
        element={
          <ProtectedRoute>
            <MessagesPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<MessagesList />} />
        <Route path={'view/:messageId'} element={<MessageView />} />
      </Route>
      <Route
        path="workouts"
        element={
          <ProtectedRoute>
            <WorkoutsPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<WorkoutsList />} />
        <Route path={'view/:trainingId'} element={<TrainingView />} />
      </Route>
      <Route path="event-locations">
        <Route index element={<EventLocationsList />} />
        <Route path={'edit/:eventLocationId'} element={<EventLocationForm />} />
        <Route path={'add'} element={<EventLocationForm />} />
      </Route>
      <Route
        path="availability"
        element={
          <ProtectedRoute>
            <AvailabilityPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<AvailabilitiesList />} />
      </Route>
      <Route
        path="services"
        element={
          <ProtectedRoute>
            <ServicesPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<ServicesList />} />
        <Route path={'edit/:serviceId'} element={<ServiceForm />} />
        <Route path={'add'} element={<ServiceForm />} />
      </Route>
      <Route
        path="welcome"
        element={
          <ProtectedRoute>
            <WelcomePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      >
        <Route index element={<ProfilePageNavigation />} />
        <Route path={'data'} element={<ProfileGeneralData />} />
        <Route path={'places'} element={<ProfilePlaces />} />
        <Route path={'contact'} element={<ProfileContactData />} />
        <Route path={'photo'} element={<ProfilePhoto />} />
        <Route path={'gallery'} element={<ProfileGallery />} />
        <Route path={'statistics'} element={<ProfileStatistics />} />
        <Route path={'settings'} element={<ProfileSettings />} />
        <Route path={'preview'} element={<ProfilePreview />} />
      </Route>
      <Route path="register">
        <Route path="confirm-email/:hash" element={<ConfirmEmailPage />} />
        <Route index element={<RegisterPage />} />
      </Route>
      <Route path="login/facebook" element={<FacebookLoginPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="terms" element={<ContentPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="*" element={<ErrorPage code={404} />} />
    </Routes>
  );
};
export default AppRoutes;
