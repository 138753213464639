import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PageContent } from './ProfilePage.styles';
import { useNavigate, useOutletContext } from 'react-router-dom';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { SportLocation } from '../../types/SportLocation';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import Former from '../../components/Former/Former';
import { FieldType, FieldTypeItem } from '../../types/FieldType';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';

const sportLocationFields: FieldType[] = [
  {
    key: 'sportLocation',
    type: 'autocomplete',
    label: 'Wybierz obiekt sportowy',
    required: true,
    labelDescription:
      'Nie ma obiektu, w którym trenujesz? Napisz do nas poprzez zakładkę Kontakt!',
  },
];

interface SportLocationField {
  sportLocation: FieldTypeItem | null;
}

const ProfilePlaces = () => {
  const navigate = useNavigate();
  const { setLoading, showMessage } = useInfo();
  const [sportLocationFieldsConfig, setSportLocationFieldsConfig] = useState<
    FieldType[]
  >([]);
  const [sportLocationField, setSportLocationField] =
    useState<SportLocationField>({
      sportLocation: null,
    });
  const [trainerSportLocations, setTrainerSportLocations] = useState<
    SportLocation[]
  >([]);
  const [trainerData, updateTrainerData] = useOutletContext<any>();

  useEffect(() => {
    reloadLocations();
  }, []);

  const reloadLocations = () => {
    setLoading(true);
    Promise.all([
      http().get('/meta/places', {
        params: { cityId: trainerData.cityId },
      }),
      http().get('/profile/trainer/places'),
    ])
      .then(
        ([{ data: sportLocations }, { data: trainerSportLocationsData }]) => {
          if (
            Array.isArray(sportLocations) &&
            Array.isArray(trainerSportLocationsData)
          ) {
            setSportLocationFieldsConfig(
              sportLocationFields.map((field) => {
                field.items = sportLocations
                  .filter(
                    (item) =>
                      !trainerSportLocationsData.find(
                        (spl) => spl.id === item.id
                      )
                  )
                  .map((item: SportLocation) => ({
                    id: item.id,
                    name: `${item.name} (${item.address}, ${item.zipcode} ${item.city})`,
                  }));
                return field;
              })
            );
            setTrainerSportLocations(trainerSportLocationsData);
          }
        }
      )
      .catch(() => Promise.reject())
      .finally(() => setLoading(false));
  };

  const onSelectSportLocation = (data: SportLocationField) =>
    setSportLocationField(data);

  const onAddSportLocation = () => {
    if (!sportLocationField?.sportLocation?.id) {
      return showMessage('Wybierz poprawnie obiekt', 'error');
    }
    setLoading(true);
    http()
      .post(`/profile/trainer/places/${sportLocationField.sportLocation?.id}`)
      .then(() => {
        reloadLocations();
        // setSportLocationField({ sportLocation: null });
        showMessage('Obiekt sportowy został wybrany', 'success');
      })
      .catch(() => showMessage('Błąd, spróbuj ponownie', 'error'))
      .finally(() => setLoading(false));
  };

  const onDeleteSportLocation = (id: number) => {
    if (window.confirm('Chcesz usunąć przypisanie do tego obiektu?')) {
      setLoading(true);
      http()
        .delete(`/profile/trainer/places/${id}`)
        .then(() => {
          reloadLocations();
          showMessage('Obiekt sportowy został usunięty', 'success');
        })
        .catch(() => showMessage('Błąd, spróbuj ponownie', 'error'))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <PageHeader
        label={'Lokalizacje treningów'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <h4 style={{ marginBottom: '10px' }}>
            Obiekty sportowe, w których prowadzisz treningi w miejscowości{' '}
            <strong style={{ textDecoration: 'underline' }}>
              {trainerData.city}
            </strong>
          </h4>
          {trainerData && (
            <Box sx={{ marginBottom: '20px' }}>
              {trainerData.everyPlaceInCity && (
                <Alert color={'info'} sx={{ mb: '10px' }}>
                  Zaznaczono wszystkie obiekty
                </Alert>
              )}

              {trainerData.everyPlaceInCity && (
                <Button
                  variant={'contained'}
                  size={'small'}
                  startIcon={<LocationOffIcon />}
                  onClick={() => updateTrainerData({ everyPlaceInCity: false })}
                >
                  Odznacz wszystkie obiekty
                </Button>
              )}
              {!trainerData.everyPlaceInCity && (
                <Button
                  variant={'contained'}
                  size={'small'}
                  startIcon={<LocationOnIcon />}
                  onClick={() => updateTrainerData({ everyPlaceInCity: true })}
                >
                  Zaznacz wszystkie obiekty
                </Button>
              )}
            </Box>
          )}

          {!trainerData.everyPlaceInCity && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Former
                  onChange={(data) => onSelectSportLocation(data)}
                  onSubmit={() => onAddSportLocation()}
                  saveButtonLabel={'Zapisz'}
                  fieldsConfig={sportLocationFieldsConfig}
                  defaultValue={sportLocationField}
                  mb={0}
                />
              </Grid>

              {trainerSportLocations.length > 0 && (
                <>
                  <Table>
                    <TableBody>
                      {trainerSportLocations.map((tSpLoc: SportLocation) => {
                        return (
                          <TableRow key={tSpLoc.id}>
                            <TableCell>{tSpLoc.name}</TableCell>
                            <TableCell>
                              {tSpLoc.address}, {tSpLoc.zipcode} {tSpLoc.city}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                size={'small'}
                                onClick={() => onDeleteSportLocation(tSpLoc.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <br />
                </>
              )}
              {/*{trainerSportLocations.length === 0 && (*/}
              {/*  <Box sx={{ marginBottom: '20px' }}>*/}
              {/*    Nie wybrano jeszcze obiektu*/}
              {/*  </Box>*/}
              {/*)}*/}
            </>
          )}
        </Grid>
      </PageContent>
    </>
  );
};
export default ProfilePlaces;
