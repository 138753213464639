export type Notification = {
  visible: boolean;
  content: string;
  type: NotificationType;
};

export type NotificationType = 'success' | 'error' | 'info' | 'warning';

export const initialNotification = {
  visible: false,
  content: '',
  type: 'info' as NotificationType,
};
