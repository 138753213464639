import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { cookieDomain, twojtrenerAppUrl } from '../config/config';

const cookies = new Cookies();

const http = () => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 120000,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': cookies.get('tt-app-token') || undefined,
      'x-api-key': process.env.REACT_APP_API_KEY || undefined,
    },
  };
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err && err.response && err.response.status) {
        if (err.response.status === 403) {
          cookies.remove('tt-app-token', { path: '/', domain: cookieDomain });
          document.location.href = twojtrenerAppUrl || '/';
        }
      }
      return Promise.reject(err);
    }
  );
  return axiosInstance;
};

export default http;
