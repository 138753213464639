import { Client } from './Client';
import { EventLocation } from './EventLocation';

export type Training = {
  id: number;
  name: string;
  description: string;
  serviceId: number;
  startDate: string;
  startTime: string;
  start?: Date;
  time: number;
  breakTime: number;
  price: number;
  visibility: number;
  participantsAmount?: number;
  maxParticipantsAmount?: number;
  sportId?: number;
  endDate?: string;
  endTime?: string;
  end?: Date;
  status?: 'finished' | 'in_progress' | 'not_started';
  isClient?: boolean;
  Clients?: Client[];
  EventLocation?: EventLocation | null;
  Sport?: { name: string; slug: string };
};
export const initialTraining: Training = {
  id: 0,
  name: '',
  description: '',
  startDate: '',
  startTime: '',
  serviceId: 0,
  time: 30,
  breakTime: 0,
  price: 0,
  visibility: 1,
  maxParticipantsAmount: 1,
  EventLocation: null,
  sportId: 0,
};
