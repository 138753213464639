import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useInfo } from '../../providers/info/info.context';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  Autocomplete,
  Switch,
} from '@mui/material';
import { PageContent, SwitchFormControl } from './ProfilePage.styles';
import BadgeIcon from '@mui/icons-material/Badge';
import { apiLoadCities, apiLoadSports } from '../../api/endpoints';
import { Dict } from '../../types/Dict';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const ProfileGeneralData = () => {
  const { setLoading } = useInfo();
  const { register, handleSubmit, reset, control } = useForm();
  const [sports, setSports] = useState<Dict[]>([]);
  const [cities, setCities] = useState<Dict[]>([]);
  const [selectedSports, setSelectedSports] = useState<Dict[]>([]);
  const navigate = useNavigate();
  const [trainerData, updateTrainerData, formErrors, setFormErrors] =
    useOutletContext<any>();

  useEffect(() => {
    loadSports();
    loadCities();
    setFormErrors({});
    if (trainerData) {
      setSelectedSports(trainerData.Sports || []);
      reset({
        name: trainerData.name,
        cityId: trainerData.cityId || 0,
        trainingLocation: trainerData.trainingLocation,
        description: trainerData.description,
        experience: trainerData.experience,
        certificates: trainerData.certificates,
        specializations: trainerData.specializations,
        phone: trainerData.phone,
        isPersonal: trainerData.isPersonal,
        isHome: trainerData.isHome,
        isOnline: trainerData.isOnline,
      });
    }
  }, [trainerData]);

  const loadCities = () => {
    setLoading(true);
    apiLoadCities()
      .then((cities) => {
        setCities(cities || []);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const loadSports = () => {
    setLoading(true);
    apiLoadSports()
      .then((sports) => {
        setSports(sports || []);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const onSubmit = (data: object) => {
    updateTrainerData({
      ...data,
      sports: selectedSports.map((item) => item.id),
    });
  };

  return (
    <>
      <PageHeader
        label={'Dane podstawowe'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={8} sm={12}>
              <FormControl sx={{ mb: 2, width: '100%' }}>
                <FormLabel>Imię i nazwisko / Nazwa *</FormLabel>
                <TextField
                  {...register('name')}
                  variant={'outlined'}
                  size={'small'}
                  autoComplete={'off'}
                  required={true}
                  error={!!formErrors.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <BadgeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {formErrors.name && (
                  <FormHelperText error={true}>
                    {formErrors.name}
                  </FormHelperText>
                )}
              </FormControl>

              {cities.length > 0 && (
                <FormControl sx={{ mb: 2, width: '100%' }}>
                  <FormLabel>
                    Miejscowość * (nie ma Twojego miasta - napisz do nas, a je
                    dodamy)
                  </FormLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={!!formErrors.cityId}
                      >
                        {cities.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    name={'cityId'}
                    control={control}
                  />
                  {formErrors.cityId && (
                    <FormHelperText error={true}>
                      {formErrors.cityId}
                    </FormHelperText>
                  )}
                </FormControl>
              )}

              <FormControl sx={{ mb: 2, width: '100%' }}>
                <FormLabel sx={{ mb: 1 }} error={!!formErrors.sports}>
                  Dyscypliny sportowe *
                </FormLabel>

                <Autocomplete
                  multiple
                  value={selectedSports}
                  options={sports}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event: any, newValue) => {
                    setSelectedSports(newValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        // icon={icon}
                        // checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={'outlined'}
                      size={'small'}
                    />
                  )}
                />
                {formErrors.sports && (
                  <FormHelperText error={true}>
                    {formErrors.sports}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ mb: 2, width: '100%' }}>
                <FormLabel>Numer telefonu (do powiadomień)</FormLabel>
                <TextField
                  {...register('phone')}
                  variant={'outlined'}
                  size={'small'}
                  autoComplete={'off'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {formErrors.phone && (
                  <FormHelperText error={true}>
                    {formErrors.phone}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ mb: 2, width: '100%' }}>
                <FormLabel>O mnie</FormLabel>
                <TextField
                  {...register('description')}
                  multiline={true}
                  rows={4}
                  variant={'outlined'}
                  size={'small'}
                />
              </FormControl>

              <Grid container sx={{ marginBottom: '20px' }}>
                <Grid item md={4} sm={12} xs={12}>
                  <SwitchFormControl>
                    <Controller
                      render={({ field }) => {
                        return <Switch {...field} checked={field.value} />;
                      }}
                      name={'isPersonal'}
                      control={control}
                    />

                    <FormLabel>trening osobisty</FormLabel>
                  </SwitchFormControl>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <SwitchFormControl>
                    <Controller
                      render={({ field }) => {
                        return <Switch {...field} checked={field.value} />;
                      }}
                      name={'isHome'}
                      control={control}
                    />

                    <FormLabel>trening domowy</FormLabel>
                  </SwitchFormControl>
                </Grid>

                <Grid item md={4} sm={12} xs={12}>
                  <SwitchFormControl>
                    <Controller
                      render={({ field }) => {
                        return <Switch {...field} checked={field.value} />;
                      }}
                      name={'isOnline'}
                      control={control}
                    />

                    <FormLabel>trening online</FormLabel>
                  </SwitchFormControl>
                </Grid>
              </Grid>

              <FormControl sx={{ mb: 5 }}>
                <Button variant={'contained'} type={'submit'}>
                  Zapisz zmiany
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </PageContent>
    </>
  );
};
export default ProfileGeneralData;
