import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { Outlet, useNavigate } from 'react-router-dom';
import { getApiValidationErrors } from "../../utils";

const ProfilePage = () => {
  const { setLoading, showMessage } = useInfo();
  const [formErrors, setFormErrors] = useState<any>({});
  const [trainerData, setTrainerData] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    loadTrainerData();
  }, []);

  const loadTrainerData = () => {
    setLoading(true);
    http()
      .get('/profile/trainer')
      .then(({ data }) => {
        data.city = (data && data.City && data.City.name) || '';
        setTrainerData(data);
        if (!data.name || !data.slug || !data.City) {
          navigate('/profile/data');
        }
      })
      .catch(() => {
        showMessage(
          'Wystąpił błąd podczas pobierania danych. Spróbuj ponownie lub skontaktuj się z nami',
          'error'
        );
        navigate('/');
      })
      .finally(() => setLoading(false));
  };

  const updateTrainerData = (data: any) => {
    showMessage('');
    setFormErrors({});
    setLoading(true);
    http()
      .patch('/profile/trainer', data)
      .then(({ data }) => {
        data.city = (data && data.City && data.City.name) || '';
        setTrainerData(data);
        showMessage('Dane zostały zaktualizowane', 'success');
      })
      .catch((errors) => {
        setFormErrors(getApiValidationErrors(errors));
        showMessage('Wystąpił błąd podczas zapisu, spróbuj ponownie', 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout variant={'full'}>
      <Outlet
        context={[trainerData, updateTrainerData, formErrors, setFormErrors]}
      />
    </Layout>
  );
};
export default ProfilePage;
