import React, { useCallback, useEffect, useState } from 'react';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useInfo } from '../../providers/info/info.context';
import Former from '../../components/Former/Former';
import { FieldType } from '../../types/FieldType';
// import { apiLoadSports } from '../../api/endpoints';
import http from '../../api/http';
import { Service, initialService } from '../../types/Service';
import { EventLocation } from '../../types/EventLocation';
import { getApiValidationErrors } from "../../utils";

export const serviceFields: FieldType[] = [
  { key: 'name', type: 'text', label: 'Nazwa', required: true },
  {
    key: 'isPublic',
    type: 'boolean',
    label: 'Widoczne w wizytówce twojtrener.pl',
    labelDescription:
      'Możesz ustawić czy usługa będzie widoczna w Twojej wizytówce',
    required: true,
  },
  {
    key: 'description',
    type: 'textarea',
    label: 'Opis',
    required: false,
  },
  {
    key: 'time',
    type: 'number',
    label: 'Czas trwania (min.)',
    required: true,
    columnSize: 6,
  },
  {
    key: 'price',
    type: 'number',
    label: 'Cena (zł)',
    required: false,
    columnSize: 6,
  },
  {
    key: 'breakTime',
    type: 'number',
    label: 'Przerwa po treningu (min.)',
    required: true,
    labelDescription:
      'Wymagane przy dodawaniu serii treningów i automatycznym kalendarzu',
    labelDescriptionOpened: true,
  },
  {
    key: 'EventLocation',
    type: 'autocomplete',
    label: 'Lokalizacja usługi',
    labelDescription:
      'Wybierz miejsce treningu z listy lub dodaj własne (adres)',
    labelDescriptionOpened: true,
    required: true,
    newOptionPossible: true,
    errorKey: 'EventLocation',
  },
];

const ServiceForm = () => {
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const { setLoading, showMessage } = useInfo();
  const [pageTitle, setPageTitle] = useState<string>('Dodaj usługę');
  const [serviceData, setServiceData] = useState<Service>(initialService);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    loadFormData();
    if (params.serviceId) {
      setPageTitle('Edycja usługi');
    }
  }, [params.serviceId]);

  const loadService = useCallback((serviceId: string) => {
    setLoading(true);
    http()
      .get(`/services/${serviceId}`)
      .then(({ data }) => {
        setServiceData({ ...data, sportId: data.sportId || 0 });
      })
      .finally(() => setLoading(false));
  }, []);

  const loadFormData = useCallback(() => {
    setLoading(true);
    http()
      .get('/event-locations')
      .then(({ data: eventLocations }) => {
        setFormFields(
          serviceFields.map((field) => {
            if (field.key === 'EventLocation') {
              field.items = eventLocations || [];
              // const defaultEventLocation =
              //   eventLocations.find(
              //     (evLoc: EventLocation) => evLoc.isDefault
              //   ) || eventLocations[0];
              // if (defaultEventLocation && !serviceData.eventLocationId) {
              //   const newServiceData = {
              //     ...serviceData,
              //     eventLocationId:
              //       (defaultEventLocation && defaultEventLocation.id) ||
              //       (eventLocations[0] && eventLocations[0].id),
              //   };
              //   setServiceData(newServiceData);
              // }
            }
            return field;
          })
        );
        if (params.serviceId) {
          loadService(params.serviceId);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = (data: object) => {
    setLoading(true);
    setFormErrors({});

    (params.serviceId
      ? http().put(`/services/${params.serviceId}`, data)
      : http().post('/services', data)
    )
      .then(() => {
        showMessage('Usługa została zapisana');
        navigate('/services');
      })
      .catch((error) => {
        setFormErrors(getApiValidationErrors(error));
        showMessage('Popraw błędy w formularzu', 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageHeader
        label={pageTitle}
        onBackButtonClick={() => navigate('/services')}
      />
      <PageContent>
        <Former
          fieldsConfig={formFields}
          formErrors={formErrors}
          defaultValue={serviceData}
          onSubmit={onSubmit}
        />
      </PageContent>
    </>
  );
};
export default ServiceForm;
