import React, { useReducer, useState } from 'react';
import { InfoContext } from './info.context';
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import {
  initialNotification,
  Notification,
  NotificationType,
} from '../../types/Notification';
import { ModalParams } from '../../modals/modals.types';
import ModalContainer from '../../modals/ModalContainer';

const infoReducerInit = { loadingsCount: 0 };
const infoReducer = (
  state: { loadingsCount: number },
  action: { type: string }
) => {
  switch (action.type) {
    case 'increment':
      return { loadingsCount: state.loadingsCount + 1 };
    case 'decrement':
      return { loadingsCount: state.loadingsCount - 1 };
    default:
      throw new Error();
  }
};

const InfoProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<Notification>(initialNotification);
  const [state, dispatch] = useReducer(infoReducer, infoReducerInit);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalParams, setModalParams] = useState<ModalParams>();

  const showMessage = (content: string, type?: NotificationType) => {
    if (!content || !type) {
      closeMessage();
    }
    setMessage({
      visible: true,
      content,
      type: type || 'success',
    });
  };

  const closeMessage = () => {
    setMessage({ ...message, visible: false });
  };

  const setLoading = (show: boolean) => {
    dispatch({ type: show ? 'increment' : 'decrement' });
  };

  const openModal = (params: any) => {
    setModalParams(params);
    setIsModalVisible(true);
  };

  const onModalCancel = (data?: any) => {
    modalParams?.onCancel && modalParams?.onCancel(data);
    setIsModalVisible(false);
  };

  const onModalChange = (data?: any) => {
    modalParams?.onChange && modalParams?.onChange(data);
    setIsModalVisible(false);
  };

  const value = {
    showMessage,
    setLoading,
    openModal,
    isLoading: state.loadingsCount > 0,
  };

  return (
    <>
      <InfoContext.Provider value={value}>
        <>{children}</>
        {modalParams?.type && (
          <ModalContainer
            isModalVisible={isModalVisible}
            type={modalParams?.type}
            onCancel={onModalCancel}
            onChange={onModalChange}
            params={modalParams?.params}
          />
        )}
        <Snackbar
          open={message.visible}
          onClose={() => closeMessage()}
          autoHideDuration={10000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={message.type}>{message.content}</Alert>
        </Snackbar>
        <Backdrop open={state.loadingsCount > 0} sx={{ color: '#fff' }}>
          <CircularProgress color={'inherit'} />
        </Backdrop>
      </InfoContext.Provider>
    </>
  );
};
export default InfoProvider;
