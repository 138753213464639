import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate, useOutletContext } from 'react-router-dom';
import EditProfilePhoto from '../../components/EditProfilePhoto/EditProfilePhoto';
import { PageContent } from './ProfilePage.styles';
import { Link } from '@mui/material';

const ProfilePhoto = () => {
  const navigate = useNavigate();
  const [trainerData] = useOutletContext<any>();

  return (
    <>
      <PageHeader
        label={'Zdjęcie profilowe'}
        onBackButtonClick={() => navigate('/profile')}
      />
      <PageContent>
        {!trainerData.slug && (
          <>
            <p>Nie można dodać zdjęcia profilowego.</p>
            <p>
              Uzupełnij{' '}
              <Link
                sx={{ cursor: 'pointer' }}
                className={'bold'}
                onClick={() => navigate('/profile/data')}
              >
                dane podstawowe
              </Link>
              , aby móc dodać zdjęcie.
            </p>
          </>
        )}
        {trainerData && trainerData.slug && (
          <EditProfilePhoto trainerSlug={trainerData.slug} />
        )}
      </PageContent>
    </>
  );
};
export default ProfilePhoto;
