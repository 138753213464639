import moment from 'moment/moment';

export interface RepeatingEventOptions {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  weekDays: string[];
}

export const initialRepeatingEventOptions = {
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(7, 'days').format('YYYY-MM-DD'),
  startTime: '08:00',
  endTime: '20:00',
  weekDays: ['1', '2', '3', '4', '5', '6', '0'],
};
