import React, { useCallback, useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { useInfo } from '../../providers/info/info.context';
import http from '../../api/http';
import { EventLocation } from '../../types/EventLocation';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { PageContent } from '../ProfilePage/ProfilePage.styles';
import AddIcon from '@mui/icons-material/Add';
import { Optional } from '../../styles/general';
import Layout from '../../components/Layout/Layout';

const EventLocationsList = () => {
  const [eventLocations, setEventLocations] = useState<EventLocation[]>([]);
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  useEffect(() => {
    loadEventLocations();
  }, []);

  const loadEventLocations = useCallback(() => {
    setLoading(true);
    http()
      .get('/event-locations')
      .then(({ data }) => {
        setEventLocations(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const onEventLocationRemove = (eventLocationId: number) => {
    if (window.confirm('Czy na pewno chcesz zarchiwizować miejsce?')) {
      setLoading(true);
      http()
        .delete(`/event-locations/${eventLocationId}`)
        .then(() => {
          showMessage('Miejsce treningu zostało zarchiwizowane', 'success');
          loadEventLocations();
        })
        .catch(() =>
          showMessage(
            'Nie można zarchiwizować miejsca treningu, spróbuj ponownie',
            'error'
          )
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <Layout variant={'full'}>
      <PageHeader
        label={'Miejsca treningów'}
        onPrimaryButtonClick={() => navigate('/event-locations/add')}
        primaryButtonIcon={<AddIcon />}
      />
      {eventLocations.length === 0 && (
        <PageContent>
          Nie dodano jeszcze żadnego miejsca treningu
        </PageContent>
      )}
      {eventLocations.length > 0 && (
        <Table>
          <TableBody>
            {eventLocations.map((evLoc, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>
                    {evLoc.name}
                    {evLoc.isDefault && (
                      <Optional style={{ marginLeft: '10px' }}>
                        Domyślnie
                      </Optional>
                    )}
                  </TableCell>
                  <TableCell width={30}>
                    {evLoc.canDelete && (
                      <IconButton
                        size={'small'}
                        onClick={() =>
                          navigate(`/event-locations/edit/${evLoc.id}`)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell width={30}>
                    {evLoc.canDelete && (
                      <IconButton
                        size={'small'}
                        onClick={() => onEventLocationRemove(evLoc.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Layout>
  );
};
export default EventLocationsList;
