export interface EventClient {
  id: number;
  present: boolean;
  comment: string;
  isNewClient?: boolean;
  isCancelled?: boolean;
  isPresent?: boolean;
  performanceRate?: number;
  commitmentRate?: number;
  clientPackageId?: number;
  paymentMethod: PaymentMethod;
}

export enum PaymentMethod {
  unpaid = 0,
  cash = 1,
  card = 2,
  package = 3,
  other = 20,
}
