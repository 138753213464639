import React, { useEffect, useState } from 'react';
import Former from '../Former/Former';
import { clientSelectFields } from './ClientSelect.config';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { Client } from '../../types/Client';
import { FieldType, FieldTypeItem } from '../../types/FieldType';
import { Box, Button, Grid, Link } from '@mui/material';
import { Color } from '../../styles/colors';

const ClientSelect = ({
  trainingId,
  onSubmit,
}: {
  trainingId: number;
  onSubmit?: (newClient: Client) => void;
}) => {
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [newEventClient, setNewEventClient] = useState<{
    client: FieldTypeItem | null;
    notifyClient: boolean;
  }>({ client: null, notifyClient: false });
  const [showClientSelect, setShowClientSelect] = useState<boolean>(false);
  const { setLoading, showMessage } = useInfo();

  useEffect(() => {
    loadClients();
  }, []);

  // TODO: move to hook
  const loadClients = () => {
    setLoading(true);
    http()
      .get('/clients', { params: { query: '' } })
      .then(({ data: clients }) => {
        setFormFields(
          clientSelectFields.map((field) => ({
            ...field,
            items: clients.map((client: Client) => ({
              id: client.id,
              name: `${client.firstName} ${client.lastName}`,
            })),
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  const onClientSelected = (data: any) => setNewEventClient(data);

  const onCreateEventClient = () => {
    setLoading(true);
    http()
      .post(`/trainings/${trainingId}/client/${newEventClient.client?.id}`, {
        notifyClient: newEventClient.notifyClient.toString() === 'true',
      })
      .then(({ data }) => {
        showMessage('Klient został przypisany do treningu', 'success');
        onSubmit && onSubmit(data?.content as Client);
      })
      .catch((error) => {
        const errMsg = error?.response?.data;
        showMessage(
          errMsg?.message || errMsg?.msg || 'Wystąpił błąd, spróbuj ponownie',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        {!showClientSelect && (
          <Link
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              setShowClientSelect(true);
            }}
          >
            Wybierz klienta do treningu
          </Link>
        )}
        <Box
          sx={{
            marginTop: '20px',
            padding: '10px 20px 20px',
            borderRadius: '4px',
            border: `solid 1px ${Color.grey}`,
            backgroundColor: Color.lightGrey2,
            position: 'relative',
            display: showClientSelect ? 'block' : 'none',
          }}
        >
          <Button
            variant={'outlined'}
            size={'small'}
            sx={{
              position: 'absolute',
              right: '20px',
              top: '5px',
              zIndex: 100,
            }}
            onClick={() => setShowClientSelect(false)}
          >
            X
          </Button>
          <Former
            fieldsConfig={formFields}
            defaultValue={newEventClient}
            onChange={onClientSelected}
            hideButtons={true}
            mb={0}
          />
          {newEventClient.client?.id && (
            <>
              <br />
              <Button
                onClick={() => onCreateEventClient()}
                variant={'contained'}
              >
                Zapisz klienta na trening
              </Button>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default ClientSelect;
