import React, { createContext } from 'react';
import { NotificationType } from '../../types/Notification';
import { ModalParams } from '../../modals/modals.types';

type InfoContextType = {
  showMessage: (content: string, type?: NotificationType) => void;
  setLoading: (show: boolean) => void;
  openModal: (params: ModalParams) => any;
  isLoading: boolean;
};

const initialInfoContext = {
  showMessage: () => false,
  setLoading: () => false,
  openModal: () => {},
  isLoading: false,
};

export const InfoContext = createContext<InfoContextType>(initialInfoContext);

export const useInfo = () => React.useContext(InfoContext);
