export type EventLocation = {
  id: number;
  name: string;
  isDefault: boolean;
  canDelete?: boolean;
};
export const initialEventLocation: EventLocation = {
  id: 0,
  name: '',
  isDefault: false,
};
