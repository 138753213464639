import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './auth.context';
import jwt_decode from 'jwt-decode';
import http from '../../api/http';
import Cookies from 'universal-cookie';
import { initialUser, User } from '../../types/User';
import { useInfo } from '../info/info.context';
import { cookieDomain } from '../../config/config';

const cookies = new Cookies();

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<User>(initialUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { showMessage, setLoading } = useInfo();

  useEffect(() => {
    const token = cookies.get('tt-app-token');
    if (token) {
      const decoded: any = jwt_decode(token);
      const exp = decoded.exp;
      const current = Math.ceil(new Date().getTime() / 1000);
      if (exp > current) {
        setToken(token);
        setUser(decoded);
        let origin = `${location.pathname}${location.search}` || '/';
        if (
          location.pathname.indexOf('login') >= 0 ||
          location.pathname.indexOf('register') >= 0
        ) {
          origin = '/profile';
        }
        navigate(origin === '/' ? '/profile' : origin, { replace: true });
      } else {
        handleLogout();
      }
    } else {
      handleLogout();
    }
  }, [navigate, location.pathname]);

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    http()
      .post('/auth/login/', { email, password })
      .then(({ data }) => {
        if (data && data.token) {
          handleToken(data.token);
        } else {
          handleLogout();
        }
      })
      .catch(() => {
        showMessage('Błąd logowania, spróbuj ponownie', 'error');
        handleLogout();
      })
      .finally(() => setLoading(false));
  };

  const handleToken = (token: string, redirect?: string) => {
    setToken(token);
    setUser(jwt_decode(token) as User);
    cookies.set('tt-app-token', token, { path: '/', domain: cookieDomain });
    const origin = redirect
      ? redirect
      : location.state?.from?.pathname || '/profile';
    navigate(origin === '/' ? '/profile' : origin);
  };

  const handleLogout = () => {
    setToken('');
    setUser(initialUser);
    cookies.remove('tt-app-token', { path: '/', domain: cookieDomain });
  };

  const value = {
    token,
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
    handleToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
