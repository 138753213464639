import http from '../api/http';
import { useInfo } from '../providers/info/info.context';
import { useNavigate } from 'react-router-dom';

export const useTrainingDelete = (cb?: () => void) => {
  const { setLoading, showMessage } = useInfo();
  const navigate = useNavigate();

  const onTrainingDelete = (trainingId: number) => {
    if (window.confirm('Czy na pewno chcesz usunąć ten trening?')) {
      setLoading(true);
      http()
        .delete(`/trainings/${trainingId}`)
        .then(() => {
          showMessage('Trening został usunięty', 'success');
          if (cb) {
            cb();
          } else {
            navigate('/workouts');
          }
        })
        .catch(() => {
          showMessage('Nie można usunąć tego treningu', 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  return onTrainingDelete;
};
