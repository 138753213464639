import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import FileUploadItem from './FileUploadItem';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const FilesUpload = ({
  trainerSlug,
  maxFilesCount = 1,
  multiFile = false,
  fileType = 10,
  onFileUploaded,
}: {
  trainerSlug: string;
  maxFilesCount?: number;
  multiFile?: boolean;
  fileType?: number;
  onFileUploaded?: (fileData: any) => void;
}) => {
  const [filesCount, setFilesCount] = useState<number>(1);
  const [uploadStarted, setUploadStarted] = useState<any>({});
  const [fileSelected, setFileSelected] = useState<boolean>(false);

  useEffect(() => {
    const newUploadStarted: any = {};
    for (let i = 0; i < filesCount; i++) {
      newUploadStarted[i] = 0;
    }
    setUploadStarted(newUploadStarted);
  }, [filesCount]);

  const addNextFile = () => {
    if (filesCount + 1 <= maxFilesCount && multiFile) {
      setFilesCount(filesCount + 1);
    }
  };

  const getFilesIds = () => {
    return Array.from(Array(filesCount).keys());
  };

  const uploadFiles = () => {
    const newUploadStarted: any = {};
    for (let i = 0; i < filesCount; i++) {
      newUploadStarted[i] = uploadStarted[i] + 1;
    }
    setUploadStarted(newUploadStarted);
  };

  const onFileSelected = (isSelected: boolean) => {
    setFileSelected(isSelected);
  };

  const onFileItemUploaded = (fileData: any) => {
    setFilesCount(0);
    onFileUploaded && onFileUploaded(fileData);
  };

  return (
    <Box>
      {getFilesIds().map((v, i) => {
        return (
          <Box key={i}>
            <FileUploadItem
              trainerSlug={trainerSlug}
              onFileUploaded={onFileItemUploaded}
              onFileSelected={onFileSelected}
              fileType={fileType}
              btnId={i}
              startUpload={uploadStarted[i]}
            />
          </Box>
        );
      })}
      <Box>
        {multiFile && maxFilesCount > filesCount && (
          <Button
            onClick={addNextFile}
            variant={'contained'}
            sx={{ mr: '10px', mb: '20px' }}
            startIcon={<AddCircleOutlineIcon />}
          >
            Dodaj kolejny plik
          </Button>
        )}
        <Button
          disabled={!fileSelected}
          variant={'contained'}
          onClick={uploadFiles}
          startIcon={<FileUploadIcon />}
          sx={{ mr: '10px', mb: '20px' }}
        >
          Prześlij plik{multiFile ? 'i' : ''}
        </Button>
      </Box>
    </Box>
  );
};
export default FilesUpload;
