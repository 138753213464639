import React, { useEffect } from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Optional } from '../../styles/general';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { FormError } from '../../types/FormError';

export interface ClientFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

const ClientForm = ({
  onChange,
  validationTrigger = 0,
  defaultData,
  isEmailDisabled,
  formErrors,
}: {
  onChange: (data: ClientFormData, isValid: boolean) => void;
  validationTrigger?: number;
  defaultData?: ClientFormData;
  isEmailDisabled?: boolean;
  formErrors?: FormError[];
}) => {
  const {
    register,
    reset,
    watch,
    control,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(defaultData || { firstName: '', lastName: '', email: '', phone: '' });
  }, []);

  useEffect(() => {
    if (Array.isArray(formErrors) && formErrors.length > 0) {
      formErrors.forEach((err) => setError(err.key, { message: err.message }));
    } else {
      clearErrors();
    }
  }, [formErrors]);

  useEffect(() => {
    if (validationTrigger > 0) {
      trigger();
    }
  }, [validationTrigger]);

  useEffect(() => {
    const subscription = watch((data) => {
      trigger().then((isValid) => {
        onChange && onChange(data as ClientFormData, isValid);
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>Imię</FormLabel>
            <TextField
              {...register('firstName', {
                required: 'Podaj imię',
                minLength: {
                  value: 3,
                  message: 'Imię musi mieć co najmniej 3 znaki',
                },
              })}
              variant={'outlined'}
              size={'small'}
              error={!!(validationTrigger > 0 && errors.firstName)}
            />
          </FormControl>
          {validationTrigger > 0 && errors.firstName && (
            <FormHelperText error={true}>
              <>{errors.firstName?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>Nazwisko</FormLabel>
            <TextField
              {...register('lastName', {
                required: 'Podaj nazwisko',
                minLength: {
                  value: 3,
                  message: 'Nazwisko musi mieć co najmniej 3 znaki',
                },
              })}
              variant={'outlined'}
              size={'small'}
              error={!!(validationTrigger > 0 && errors.lastName)}
            />
          </FormControl>
          {validationTrigger > 0 && errors.lastName && (
            <FormHelperText error={true}>
              <>{errors.lastName?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>Adres e-mail</FormLabel>
            <TextField
              {...register('email', {
                required: 'Podaj poprawny adres e-mail',
              })}
              variant={'outlined'}
              size={'small'}
              type={'email'}
              error={!!(validationTrigger > 0 && errors.email)}
              disabled={isEmailDisabled}
            />
          </FormControl>
          {validationTrigger > 0 && errors.email && (
            <FormHelperText error={true}>
              <>{errors.email?.message}</>
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel>
              Numer telefonu <Optional>opcjonalne</Optional>
            </FormLabel>
            <Controller
              render={({ field }) => (
                <ReactPhoneInput
                  {...field}
                  country={'pl'}
                  enableAreaCodes={false}
                  countryCodeEditable={false}
                  onlyCountries={['pl']}
                  component={TextField}
                  label={''}
                  inputProps={{ variant: 'outlined', size: 'small' }}
                />
              )}
              name={'phone'}
              control={control}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
export default ClientForm;
