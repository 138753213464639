import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar/AppBar';
import { drawerWidth } from './Navigation';
import { useAuth } from '../../providers/auth/auth.context';
import { Avatar, Box } from '@mui/material';
import { UserLoggedIn, UserName } from './Layout.styles';
import { Color } from '../../styles/colors';
import { useNavigate } from 'react-router-dom';

const Header = ({
  handleNavigationToggle,
}: {
  handleNavigationToggle: () => void;
}) => {
  const navigate = useNavigate();
  const { user, token } = useAuth();

  return (
    <AppBar
      position={'fixed'}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: Color.black,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          color="inherit"
          onClick={handleNavigationToggle}
          edge="start"
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box component={'div'} sx={{ flexGow: 1 }}>
          <img
            src={'/img/img_logo_twojtrener_white.png'}
            height={30}
            alt={'twojtrener.pl'}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
        </Box>
        {token && user && (
          <UserLoggedIn>
            <Avatar sx={{ width: 32, height: 32 }} />
            <UserName
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              {user.email}
            </UserName>
          </UserLoggedIn>
        )}
      </Toolbar>
    </AppBar>
  );
};
export default Header;
