import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
} from '@mui/material';
import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import http from '../../api/http';
import { useInfo } from '../../providers/info/info.context';
import { Color } from '../../styles/colors';

const FileUploadItem = ({
  trainerSlug,
  btnId = 0,
  startUpload = 0,
  fileType = 10,
  onFileSelected,
  onFileUploaded,
}: {
  trainerSlug: string;
  btnId?: number;
  startUpload: number;
  fileType?: number;
  onFileSelected?: (isSelected: boolean) => void;
  onFileUploaded?: (fileData: any) => void;
}) => {
  const [file, setFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const fileRef = useRef<any>();
  const { showMessage } = useInfo();

  useEffect(() => {
    if (startUpload > 0 && file && file.size) {
      upload();
    }
  }, [startUpload]);

  const onFileChange = (e: any) => {
    setProgress(0);
    const file = e.target.files[0];
    setFile(file);
    if (onFileSelected) {
      onFileSelected(true);
    }
  };

  const onFileDelete = () => {
    setFile(undefined);
    if (onFileSelected) {
      onFileSelected(false);
    }
  };

  const upload = () => {
    setProgress(0);
    setIsUploading(true);

    const formData = new FormData();
    if (file) {
      formData.append('file', file, file?.name); // appending file
    }

    let apiUrl = `/files/${trainerSlug}/profile-photo`;
    if (fileType === 20) {
      apiUrl = `/files/${trainerSlug}/gallery`;
    }

    http()
      .post(apiUrl, formData, {
        onUploadProgress: (ProgressEvent) => {
          const fileProgress = ProgressEvent.total
            ? Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
            : 0;
          setProgress(fileProgress);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        setIsUploading(false);
        if (onFileUploaded) {
          onFileUploaded(data);
        }
        showMessage('Plik został przesłany', 'success');
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.message) {
          showMessage(response.data.message, 'error');
        } else {
          showMessage(
            'Wystąpił błąd podczas przesyłania pliku, spróbuj ponownie',
            'error'
          );
        }
      })
      .finally(() => {
        setProgress(0);
        setIsUploading(false);
      });
  };

  return (
    <Box
      sx={{
        minHeight: '70px',
        mb: '10px',
      }}
    >
      {file && file.name && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '20px' }}>
          <Box sx={{ minWidth: '30px', pr: '10px' }}>
            {!isUploading && (
              <Button
                onClick={onFileDelete}
                startIcon={<DeleteIcon />}
                variant={'outlined'}
                size={'small'}
                sx={{ marginRight: '20px' }}
              >
                Usuń
              </Button>
            )}
          </Box>
          <Box sx={{ width: '100%' }}>
            <FileName>{file.name}</FileName>
          </Box>
        </Box>
      )}

      {!isUploading && !(file && file.name) && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UploadButton htmlFor={`uploadFileInput_${btnId}`}>
            <Input
              id={`uploadFileInput_${btnId}`}
              type="file"
              sx={{ display: 'none' }}
              inputRef={fileRef}
              onChange={onFileChange}
            />
            Wybierz plik (max 5MB)
          </UploadButton>
        </Box>
      )}

      {isUploading && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress value={progress} variant="determinate" />
          </Box>
          <Box sx={{ minWidth: '50px', pl: '10px' }}>{progress}%</Box>
        </Box>
      )}
    </Box>
  );
};
export default FileUploadItem;

// styles
const FileName = styled(Box)({
  fontSize: 14,
  maxWidth: '90%',
  overflow: 'hidden',
  textWrap: 'nowrap',
  textOverflow: 'ellipsis',
});

export const FormItemError = styled(Box)({
  color: 'red',
  marginBottom: '5px',
  fontSize: 15,
});

export const UploadButton = styled(InputLabel)({
  fontSize: 15,
  width: 250,
  textAlign: 'center',
  backgroundColor: Color.ttViolet,
  color: Color.white,
  padding: '6px 16px',
  cursor: 'pointer',
  borderRadius: 4,
  ':hover': {
    backgroundColor: Color.ttVioletDark,
  },
});
