import React, { useEffect, useState } from 'react';
import http from '../../api/http';
import { FieldType } from '../../types/FieldType';
import Former from '../../components/Former/Former';
import { useInfo } from '../../providers/info/info.context';
import { initialPackage, Package } from '../../types/Package';
import { getApiValidationErrors } from "../../utils";

export const packageFields: FieldType[] = [
  { key: 'name', type: 'text', label: 'Nazwa', required: true },
  {
    key: 'trainingsCount',
    type: 'number',
    label: 'Ilość treningów w pakiecie',
    required: true,
  },
  {
    key: 'price',
    type: 'number',
    label: 'Cena (zł)',
    required: true,
  },
  {
    key: 'durationDaysCount',
    type: 'number',
    label: 'Czas ważności pakietu (ilość dni)',
    required: true,
  },
  {
    key: 'description',
    type: 'textarea',
    label: 'Opis pakietu',
  },
  {
    key: 'isPublic',
    type: 'boolean',
    label: 'Widoczne w wizytówce twojtrener.pl',
    labelDescription:
      'Możesz ustawić czy pakiet będzie widoczny w Twojej wizytówce',
    required: true,
  },
];

const PackageForm = ({
  packageId,
  submitFormTrigger = 0,
  onFormSubmit,
}: {
  packageId?: number;
  submitFormTrigger: number;
  onFormSubmit?: (data: Package) => void;
}) => {
  const [formFields, setFormFields] = useState<FieldType[]>([]);
  const [formErrors, setFormErrors] = useState<any>({});
  const { setLoading, showMessage } = useInfo();
  const [packageData, setPackageData] = useState<Package>(initialPackage);

  useEffect(() => {
    if (packageId) {
      loadPackage(packageId);
    } else {
      setFormFields(packageFields);
    }
  }, [packageId]);

  const loadPackage = (packageId: number) => {
    setLoading(true);
    http()
      .get(`/packages/${packageId}`)
      .then(({ data }) => {
        setPackageData(data);
        setFormFields(packageFields);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data: Package) => {
    setLoading(true);
    setFormErrors({});

    (packageId
      ? http().put(`/packages/${packageId}`, data)
      : http().post('/packages', data)
    )
      .then(({ data: createdPackage }) => {
        showMessage('Pakiet został zapisany');
        onFormSubmit && onFormSubmit({ ...data, ...createdPackage?.content });
      })
      .catch((error) => {
        setFormErrors(getApiValidationErrors(error));
        showMessage('Popraw błędy w formularzu', 'error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Former
        fieldsConfig={formFields}
        formErrors={formErrors}
        defaultValue={packageData}
        onSubmit={onSubmit}
        submitTrigger={submitFormTrigger}
        hideButtons={true}
      />
    </>
  );
};
export default PackageForm;
